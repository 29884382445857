import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  platformLinks: {
    display: 'flex',
    alignItems: 'center',

    '& $shareButton': {
      paddingLeft: 4,
      justifyContent: 'flex-start',
      '& svg': {
        color: '#ffffff',
        width: '0.95em',
        height: '0.95em',
      },
      '&:hover': {
        '& svg': {
          transform: 'scale(1.1)',
        },
      },
    },
  },
  platformButton: {
    '& img': {
      width: '0.95em',
      height: '0.95em',
      transition: 'transform 0.2s ease-in-out',
    },
    '&:hover': {
      '& img': {
        transform: 'scale(1.1)',
      },
    },
  },
  shareWrapper: {
    position: 'relative',
  },
  sharePopper: {
    zIndex: 1300,
  },
  sharePaper: {
    backgroundColor: '#2a2a2a !important',
    marginTop: 5,
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.5) !important',
  },
  shareList: {
    padding: '8px 0',
  },
  shareItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    padding: '8px 16px',
    cursor: 'pointer',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
  },
  socialIcon: {
    width: 32,
    height: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    '& svg': {
      width: 18,
      height: 18,
      color: '#ffffff',
    },
  },
  facebookIcon: {
    backgroundColor: '#1877f2',
  },
  xIcon: {
    backgroundColor: '#000000',
  },
  emailIcon: {
    backgroundColor: '#808080',
    '& svg': {
      color: '#2a2a2a',
    },
  },
  shareButton: {},
  linkField: {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      borderRadius: '10px !important',
      paddingRight: '8px !important',
      color: 'white',
      '& fieldset': {
        borderColor: 'rgba(255, 255, 255, 0.23)',
      },
      '&:hover fieldset': {
        borderColor: 'rgba(255, 255, 255, 0.4)',
      },
    },
    '& .MuiInputBase-input': {
      cursor: 'pointer',
    },
  },
  copyButton: {
    color: '#ffffff',
    backgroundColor: '#f56100',
    cursor: 'pointer',
    padding: '4px 8px',
    marginRight: 0,
    fontSize: 14,
    fontWeight: 500,
    borderRadius: 10,
    '&:hover': {
      filter: 'brightness(120%)',
    },
  },
}));
