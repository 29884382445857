import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  podcastCard: {
    boxSizing: 'border-box',
    position: 'relative',
    backgroundColor: '#000',
    borderRadius: 8,
    display: 'flex !important',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    transition: 'transform 0.3s ease-in-out',
    outline: 'none',
    padding: 10,
    width: 210,
    minHeight: 380,

    [theme.breakpoints.down('sm')]: {
      '&.browseCard': {
        width: 155,
      },
    },
    '& $podcastTitle': {
      color: 'white',
      fontSize: '1rem',
      fontWeight: 'bold',
      textAlign: 'center',
      marginBottom: '0.5rem',
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxHeight: '3rem',
      minHeight: 48,
      lineHeight: '1.5rem',
      padding: '0 5px',
    },
    '& $podcastRank': {
      fontSize: 52,
      fontWeight: 600,
      color: '#f56100',
    },
    '& $podcastGenre': {
      fontSize: 16,
      fontWeight: 400,
      color: '#46c0ec',
      fontStyle: 'italic',
    },
    '& $podcastCountry': {
      fontSize: 14,
      fontWeight: 400,
      color: '#fff',
      marginTop: 5,
    },
    '& $historyRank': {
      boxSizing: 'border-box',
      color: 'white',
      borderRadius: '50%',
      backgroundColor: '#2f2f2f',
      width: 65,
      height: 65,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      transform: 'scale(0.8) translateX(12px)',
      '& svg': {
        marginLeft: -5,
        marginBottom: 2,
        fontSize: 22,
      },
      '& $historyRankUp': {
        display: 'flex',
        alignItems: 'center',
        fontSize: 26,
        fontWeight: 600,
        color: '#9df500',
        '& svg': {
          fill: '#9df500',
        },
      },
      '& $historyRankDown': {
        display: 'flex',
        alignItems: 'center',
        fontSize: 26,
        fontWeight: 600,
        color: '#f50000',
      },
    },
  },
  podcastImage: {
    width: '100%',
    height: 188,
    borderRadius: 10,
    marginBottom: 10,
    [theme.breakpoints.down('sm')]: {
      height: 240,

      '&.browseImage': {
        height: 150,
      },
    },
  },
  podcastIcon: {
    width: 20,
    height: 20,
    marginRight: 5,
  },
  rankContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    padding: '5px 5px 0',
    boxSizing: 'border-box',
    alignItems: 'center',
    marginTop: 'auto',
  },
  noPodcastsText: {
    color: 'white',
    textAlign: 'center',
    fontSize: '1.2rem',
    marginBottom: '2rem !important',
  },
  podcastImageWrapper: {
    position: 'relative',
    width: '100%',
    '&:hover $imageOverlay': {
      opacity: 1,
    },
  },
  imageOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
    opacity: 0,
    transition: 'opacity 0.5s ease',
    borderRadius: 10,
    '& $overlayButton': {
      color: 'white ',
      fontSize: 14,
      padding: '5px 10px ',
      '&:hover': {
        color: '#46c0ec',
        backgroundColor: 'transparent',
      },
    },
  },
  overlayButton: {},
  podcastRank: {},
  podcastTitle: {},
  podcastGenre: {},
  historyRank: {},
  historyRankUp: {},
  historyRankDown: {},
  podcastCountry: {},
}));
