import { useEffect, useRef, useCallback } from 'react';
import { useDispatch, useStore, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import {
  fetchPodcasts,
  resetPodcasts,
  setCurrentPodcast,
  setSelectedGenre,
  resetPodcastsByGenre,
  fetchTopPodcastsForDemo,
  resetDemoModePodcasts,
} from '../redux/slices/podcastsSlice';
import { getRandomCountry } from '../utils/randomPodcast';
import { getFavorites } from '../utils/favorites';

const normalizeGenre = (genre) => (genre === 'Podcasts' ? 'Top Podcasts' : genre);

const FAVORITES_THRESHOLD = 20;

export const useAutoDemo = () => {
  const dispatch = useDispatch();
  const store = useStore();
  const location = useLocation();
  const isFirstLoadRef = useRef(true);
  const demoTimeoutRef = useRef(null);
  const isUserInteractedRef = useRef(false);
  const isTabActiveRef = useRef(true);
  const isDemoAllowedRef = useRef(true);
  const currentPodcastIndexRef = useRef(0);
  const needNewBatchRef = useRef(true);
  const { isOnlyFavoritesForDemo } = useSelector((state) => state.podcasts);
  const isCleanUrl = location.search === '';

  const canStartDemo = useCallback(() => {
    const conditions = {
      isFirstLoad: isFirstLoadRef.current,
      isTabActive: isTabActiveRef.current,
      isNotInteracted: !isUserInteractedRef.current,
      isDemoAllowed: isDemoAllowedRef.current,
      isCleanUrl: isCleanUrl,
    };

    return Object.values(conditions).every(Boolean);
  }, [isCleanUrl]);

  const loadDemoPodcastsAndStart = useCallback(async () => {
    if (!canStartDemo()) return;

    const getPodcastsState = () => store.getState().podcasts;

    const handleDemoRestart = () => {
      if (canStartDemo()) {
        loadDemoPodcastsAndStart();
      }
    };

    const handleError = (error) => {
      console.error('Demo mode error:', error);
      demoTimeoutRef.current = setTimeout(handleDemoRestart, 1000);
    };

    const startNextDemoCycle = () => {
      dispatch(setSelectedGenre(null));
      dispatch(resetPodcastsByGenre());
      demoTimeoutRef.current = setTimeout(handleDemoRestart, 100);
    };

    const waitForPodcastsCarousel = (correctGenre) => {
      const checkPodcastsCarousel = () => {
        const state = getPodcastsState();
        if (state.podcastsByGenre[correctGenre]?.length > 0) {
          demoTimeoutRef.current = setTimeout(() => {
            if (!canStartDemo()) return;
            startNextDemoCycle();
          }, 10000);
        } else {
          demoTimeoutRef.current = setTimeout(checkPodcastsCarousel, 100);
        }
      };
      checkPodcastsCarousel();
    };

    const handlePodcastGenre = (currentPodcast) => {
      const state = getPodcastsState();
      const firstAvailableGenre = currentPodcast.genres.find((genre) =>
        state.genres.some((g) => g.name === normalizeGenre(genre))
      );

      if (firstAvailableGenre) {
        const correctGenre = normalizeGenre(firstAvailableGenre);
        dispatch(setSelectedGenre(correctGenre));
        waitForPodcastsCarousel(correctGenre);
      } else {
        startNextDemoCycle();
      }
    };

    const handlePodcastFound = (foundPodcast) => {
      if (!foundPodcast || !canStartDemo()) return;

      dispatch(setCurrentPodcast(foundPodcast));
      demoTimeoutRef.current = setTimeout(() => {
        if (!canStartDemo()) return;
        handlePodcastGenre(foundPodcast);
      }, 9000);
    };

    const favorites = getFavorites();
    const isOnlyFavoritesForDemo = getPodcastsState().isOnlyFavoritesForDemo;
    const hasSufficientFavorites = favorites.length >= FAVORITES_THRESHOLD;
    const batchSize = hasSufficientFavorites ? favorites.length : FAVORITES_THRESHOLD;

    if (needNewBatchRef.current || getPodcastsState().demoModePodcasts.length === 0) {
      try {
        console.log('fetching podcasts', currentPodcastIndexRef.current);
        await dispatch(
          fetchTopPodcastsForDemo({
            startDate: moment().startOf('day').format(),
            country: getRandomCountry(),
            genre: 'Top Podcasts',
            batchSize,
            favorites: favorites.map(fav => fav.podcastId).join(','),
            onlyFavorites: isOnlyFavoritesForDemo,
          })
        ).unwrap();

        if (!canStartDemo()) return;

        currentPodcastIndexRef.current = 0;
        needNewBatchRef.current = false;
      } catch (error) {
        handleError(error);
        return;
      }
    }

    const state = getPodcastsState();
    const currentPodcast = state.demoModePodcasts[currentPodcastIndexRef.current];

    try {
      const response = await dispatch(fetchPodcasts(currentPodcast.name)).unwrap();
      const foundPodcast = response.find(
        (item) => item.collectionName.toLowerCase() === currentPodcast.name.toLowerCase()
      );
      handlePodcastFound(foundPodcast);
      currentPodcastIndexRef.current++;

      if (currentPodcastIndexRef.current >= state.demoModePodcasts.length) {
        needNewBatchRef.current = true;
      }
    } catch (error) {
      handleError(error);
    }
  }, [store, dispatch, canStartDemo]);

  useEffect(() => {
    if (isOnlyFavoritesForDemo) {
      isFirstLoadRef.current = true;
      isUserInteractedRef.current = false;
      isDemoAllowedRef.current = true;
      needNewBatchRef.current = true;
      currentPodcastIndexRef.current = 0;
      loadDemoPodcastsAndStart();
    }
  }, [isOnlyFavoritesForDemo, loadDemoPodcastsAndStart]);

  useEffect(() => {
    if (!isCleanUrl) {
      isDemoAllowedRef.current = false;
      return;
    }

    if (canStartDemo()) {
      dispatch(setSelectedGenre(null));
      dispatch(resetPodcasts());
      loadDemoPodcastsAndStart();
    }

    const handleUserInteraction = () => {
      isUserInteractedRef.current = true;
      isFirstLoadRef.current = false;
      isDemoAllowedRef.current = false;
      dispatch(resetDemoModePodcasts());

      if (demoTimeoutRef.current) {
        clearTimeout(demoTimeoutRef.current);
      }
    };

    const handleVisibilityChange = () => {
      isTabActiveRef.current = document.visibilityState === 'visible';
      if (!isTabActiveRef.current && demoTimeoutRef.current) {
        clearTimeout(demoTimeoutRef.current);
      } else if (canStartDemo()) {
        loadDemoPodcastsAndStart();
      }
    };

    const events = ['keydown', 'mousedown', 'wheel', 'touchstart'];
    events.forEach((event) => {
      document.addEventListener(event, handleUserInteraction, { capture: true });
    });

    document.addEventListener('visibilitychange', handleVisibilityChange, { capture: true });

    return () => {
      if (demoTimeoutRef.current) {
        clearTimeout(demoTimeoutRef.current);
      }
      events.forEach((event) => {
        document.removeEventListener(event, handleUserInteraction, { capture: true });
      });
      document.removeEventListener('visibilitychange', handleVisibilityChange, { capture: true });
    };
  }, [isCleanUrl, loadDemoPodcastsAndStart, dispatch, canStartDemo]);
};
