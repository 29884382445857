import axios from 'axios';

export const searchPodcasts = async (name) => {
  const response = await axios.get(`/api/top-podcasts/search`, { params: { name } });
  return response.data;
};

export const getTopPodcasts = async (params) => {
  const response = await axios.get(`/api/top-podcasts/`, { params });
  return response.data;
};

export const getPodcastsByGenre = async (params) => {
  const response = await axios.get(`/api/top-podcasts/by-genre`, { params });
  return response.data;
};

export const getPodcastReleaseDate = async (feedUrl) => {
  const response = await axios.get(`/api/top-podcasts/get-release-date`, { params: { feedUrl } });
  return response.data;
};

export const getGenres = async () => {
  const response = await axios.get(`/api/top-podcasts/genres`);
  return response.data;
};

export const getTopPodcastsForDemo = async (params) => {
  const response = await axios.get(`/api/top-podcasts/demo`, { params });
  return response.data;
};

