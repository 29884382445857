import { makeStyles } from '@mui/styles';
import lastRankBg from '../../assets/images/last-rank.svg';

export const useStyles = makeStyles(() => ({
  container: {
    position: 'absolute',
    width: 49,
    height: 38,
    backgroundImage: `url(${lastRankBg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 600,
    fontSize: '1em',
    color: '#fff',
  },
})); 