import React, { useEffect, useState, useRef } from 'react';
import { Box, Grid2, CircularProgress, Pagination, IconButton } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { fetchPodcastsByGenre } from '../../redux/slices/podcastsSlice';
import { PodcastCard } from '../PodcastCard/PodcastCard';
import { useStyles } from './styles';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import CloseIcon from '@mui/icons-material/Close';
import { setShowBrowseGrid, setBrowseMode, resetBrowseMode } from '../../redux/slices/podcastsSlice';

const ITEMS_PER_PAGE = 24;

export const BrowseCharts = () => {
  const classes = useStyles();
  const browseRef = useRef(null);
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const { podcastsByGenre, browseModeCountry, browseModeGenre, status } = useSelector(
    (state) => state.podcasts
  );
  const loading = status === 'loading';

  useEffect(() => {
    dispatch(
      fetchPodcastsByGenre({
        genre: browseModeGenre,
        country: browseModeCountry,
      })
    );
  }, [browseModeGenre, browseModeCountry, dispatch]);

  const handlePageChange = (event, value) => setPage(value);

  const currentPodcasts = podcastsByGenre[browseModeGenre] || [];
  const paginatedPodcasts = currentPodcasts.slice((page - 1) * ITEMS_PER_PAGE, page * ITEMS_PER_PAGE);
  const pageCount = Math.ceil(currentPodcasts.length / ITEMS_PER_PAGE);

  const handleFirstPage = () => setPage(1);
  const handleLastPage = () => setPage(pageCount);

  const updateHeight = () => {
    if (browseRef.current) {
      const height = browseRef.current.offsetHeight;
      document.documentElement.style.setProperty('--browse-height', `${height + 220}px`);
    }
  };

  useEffect(() => {
    const timer = setTimeout(updateHeight, 100);
    return () => {
      clearTimeout(timer);
      document.documentElement.style.setProperty('--browse-height', '100vh');
    };
  }, [podcastsByGenre, loading]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    const timer = setTimeout(updateHeight, 600);
    return () => clearTimeout(timer);
  }, [page]);

  const handleClose = () => {
    dispatch(setShowBrowseGrid(false));
    dispatch(setBrowseMode(false));
    dispatch(resetBrowseMode());
  };

  return (
    <Box ref={browseRef} className={classes.root}>
      <Box className={classes.closeButtonWrapper}>
        <IconButton className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Grid2
        container
        spacing={3}
        columns={{ xs: 1, sm: 2, md: 3, lg: 4 }}
        justifyContent="center"
        minHeight="400px"
      >
        {loading ? (
          <Box display="flex" justifyContent="center" width="100%" alignItems="center">
            <CircularProgress />
          </Box>
        ) : (
          paginatedPodcasts.map((podcast, index) => (
            <Grid2 item xs={1} key={index}>
              <PodcastCard podcast={podcast} />
            </Grid2>
          ))
        )}
      </Grid2>
      {pageCount > 1 && !loading && (
        <Box display="flex" justifyContent="center" alignItems="center" mt={3} mb={8} gap={1}>
          <IconButton onClick={handleFirstPage} disabled={page === 1} className={classes.pageButton}>
            <FirstPageIcon />
          </IconButton>
          <Pagination
            count={pageCount}
            page={page}
            onChange={handlePageChange}
            color="primary"
            size="large"
            className={classes.pagination}
          />
          <IconButton onClick={handleLastPage} disabled={page === pageCount} className={classes.pageButton}>
            <LastPageIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};
