export const modalText = {
  mowsub: {
    text: 'Our flagship podcast subscriber product! MowPod will advertise your podcast and drive them to a landing page on our site where we give them a full description of the podcast, and allow users to listen to the latest episode. We provide a big link for them to follow you on their favorite platform.',
    buttonText: 'SCHEDULE A DEMO',
    link: '#',
  },
  mowboost: {
    text: 'Our newest product allows users to listen to your podcast on either Spotify or Apple and give you feedback on the podcast. We start with a basic set of three questions and you can expand from there.\n \n Ask anything from, “Was the Host funny” or “ What would you like to hear in a future episode?” to “Would you like more info or a sample product from our advertiser?” \n\n  We can even API in the email addresses and names of those listeners to your mailchimp or other ESP.  And as always, we’ve got beautiful detailed reporting.',
    buttonText: 'SCHEDULE A DEMO',
    link: '#',
  },
  youtube: {
    text: 'Have a video on YouTube to go along with your podcast? Our team can promote the video along with the audio podcast or as standalone content.\n \n People can debate on the importance of video with podcasts all day long…..but if you’ve got a video, you may as well reach that audience too!',
    buttonText: 'SCHEDULE A DEMO',
    link: '#',
  },
  selfserve: {
    text: 'The same technology used in our MowPod boost enterprise technology put together in a simple self-serve manner for independent podcasts.\n\n Assisted with AI Targeting and text generation, Indy Podcasts can spend as little as $100 and boost an episode in under two minutes!\n\n Just paste in your RSS feed and go! You can monitor the campaign in near real time, and after it ends, download the same detailed reporting as the big networks using the enterprise version.',
    buttonText: 'PROMOTE YOUR PODCAST',
    link: 'https://boost.mowpod.com',
  },
  boost: {
    text: 'Our original enterprise product with a beautiful upgrade. Sometimes you just need downloads to a specific episode for an advertiser or special guest. MowPods advanced targeting and programmatic advertising allows you to hit those exact users wherever they happen to be on the Internet. Our upgraded player now allows for video and text subtitles. You can send us the video, a still screen, or have us run AI generated B-roll footage for a bit of extra flare.',
    buttonText: 'SCHEDULE A DEMO',
    link: '#',
  },
};
