import React, { useEffect } from 'react';
import { Box, CircularProgress } from '@mui/material';
import bg from '../assets/images/bg.svg';
import bgBtn from '../assets/images/btn.png';
import mowpodCharts from '../assets/images/mowpod-charts.png';
import { Search } from '../components/Search/Search';
import { EmailNotify } from '../components/EmailNotify/EmailNotify';
import { PodcastInfo } from '../components/PodcastInfo/PodcastInfo';
import { useStyles } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { PodcastCharts } from '../components/PodcastCharts/PodcastCharts';
import Chart from '../components/Chart/Chart';
import { Footer } from '../components/Footer/Footer';
import { useAutoDemo } from '../hooks/useAutoDemo';
import {
  fetchGenres,
  fetchTopPodcasts,
  resetGenres,
} from '../redux/slices/podcastsSlice';
import { BrowseCharts } from '../components/BrowsePodcasts/BrowsePodcasts';

export const Charts = () => {
  const {
    currentPodcast,
    startDate,
    endDate,
    topPodcasts: { loading },
    showBrowseGrid,
    browseMode,
  } = useSelector((state) => state.podcasts);
  const classes = useStyles({ bg, bgBtn });
  const dispatch = useDispatch();

  useAutoDemo();

  useEffect(() => {   
    dispatch(fetchGenres());
  }, []);

  useEffect(() => {
    if (currentPodcast) {
      dispatch(resetGenres());
      dispatch(
        fetchTopPodcasts({
          podcastId: currentPodcast.collectionId,
          startDate,
          endDate,
          feedUrl: currentPodcast.feedUrl,
        })
      );
    }
  }, [currentPodcast, dispatch, startDate, endDate]);

  return (
    <Box className={classes.container}>
      <Box className={classes.logoWrapper}>
        <img src={mowpodCharts} alt="Mowpod Charts" className={classes.logo} />
      </Box>
      <Box className={classes.header}>
        <Box className={classes.headerTop}>
          <Search />
          {!browseMode && <EmailNotify />}
        </Box>
        <PodcastInfo />
        <Box className={classes.divider} />
      </Box>

      {loading ? (
        <Box display="flex" justifyContent="center" width="100%">
          <CircularProgress />
        </Box>
      ) : (
        <>
          {showBrowseGrid && <BrowseCharts />}
          <Chart />
          <Box className={classes.header}>{currentPodcast && <PodcastCharts />}</Box>
        </>
      )}
      <Footer />
    </Box>
  );
};
