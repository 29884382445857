import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getTopPodcasts,
  searchPodcasts,
  getPodcastsByGenre,
  getPodcastReleaseDate,
  getGenres,
  getTopPodcastsForDemo,
} from '../../api/podcasts';
import moment from 'moment';
import { getFavorites, saveFavorites } from '../../utils/favorites';

export const fetchPodcasts = createAsyncThunk('podcasts/fetchPodcasts', async (searchValue) => {
  const response = await searchPodcasts(searchValue);
  return response;
});

export const fetchTopPodcasts = createAsyncThunk('podcasts/fetchTopPodcasts', async (params) => {
  const response = await getTopPodcasts(params);
  return response;
});

export const fetchTopPodcastsForDemo = createAsyncThunk(
  'podcasts/fetchTopPodcastsForDemo',
  async (params) => {
    const response = await getTopPodcastsForDemo(params);
    return response;
  }
);

export const fetchPodcastsByGenre = createAsyncThunk('podcasts/fetchPodcastsByGenre', async (params) => {
  const response = await getPodcastsByGenre(params);
  return response;
});

export const fetchPodcastReleaseDate = createAsyncThunk(
  'podcasts/fetchPodcastReleaseDate',
  async (feedUrl) => {
    const response = await getPodcastReleaseDate(feedUrl);
    return response;
  }
);

export const fetchGenres = createAsyncThunk('podcasts/fetchGenres', async () => {
  const response = await getGenres();
  return response;
});

export const loadPodcastCharts = createAsyncThunk(
  'podcasts/loadPodcastCharts',
  async (podcast, { dispatch }) => {
    const response = await dispatch(fetchPodcasts(podcast.name)).unwrap();
    const foundPodcast = response.find(
      (item) => item.collectionName.toLowerCase() === podcast.name.toLowerCase()
    );

    dispatch(
      fetchTopPodcasts({
        podcastId: foundPodcast.collectionId,
        startDate: moment().subtract(30, 'days').format(),
        endDate: moment().format(),
      })
    );

    return foundPodcast;
  }
);

export const setFavorites = createAsyncThunk(
  'podcasts/setFavorites',
  async ({ podcastId, name }, { getState }) => {
    const { favorites } = getState().podcasts;
    const newFavorites = favorites.some((fav) => fav.podcastId === podcastId)
      ? favorites.filter((fav) => fav.podcastId !== podcastId)
      : [...favorites, { podcastId, name }];

    saveFavorites(newFavorites);

    return newFavorites;
  }
);

const initialState = {
  podcasts: {
    data: [],
    loading: false,
    error: null,
  },
  allGenres: {
    data: [],
    loading: false,
    error: null,
  },
  demoModePodcasts: [],
  podcastsByGenre: {},
  currentPodcast: null,
  selectedGenre: null,
  selectedCountry: 'United States',
  startDate: moment().subtract(60, 'days').format(),
  endDate: moment().format(),
  status: null,
  error: null,
  episodes: [],
  topPodcasts: {
    data: [],
    loading: false,
    error: null,
  },
  genres: [],
  hiddenGenres: [],
  browseMode: false,
  browseModeGenre: 'Top Podcasts',
  browseModeCountry: 'United States',
  showBrowseGrid: false,
  favorites: getFavorites(),
  isOnlyFavoritesForDemo: false,
};

export const podcastsSlice = createSlice({
  name: 'podcasts',
  initialState,
  reducers: {
    setCurrentPodcast: (state, action) => {
      state.currentPodcast = action.payload;
    },
    setSelectedGenre: (state, action) => {
      state.selectedGenre = action.payload;
    },
    resetPodcasts: (state) => {
      state.podcasts.data = [];
      state.episodes = [];
      state.topPodcasts = {
        data: [],
        loading: false,
        error: null,
      };
    },
    resetDemoModePodcasts: (state) => {
      state.demoModePodcasts = [];
    },
    setSelectedCountry: (state, action) => {
      state.selectedCountry = action.payload;
    },
    resetPodcastsByGenre: (state) => {
      state.podcastsByGenre = {};
    },
    setDateRange: (state, action) => {
      const { startDate, endDate } = action.payload;
      state.startDate = startDate;
      state.endDate = endDate;
    },
    resetDateRange: (state) => {
      const defaultDays = window.innerWidth <= 600 ? 7 : 30;
      state.startDate = moment().subtract(defaultDays, 'days').format();
      state.endDate = moment().format();
    },
    setGenres: (state, action) => {
      state.genres = action.payload;
    },
    toggleHiddenGenre: (state, action) => {
      const genre = action.payload;
      const index = state.hiddenGenres.indexOf(genre);
      if (index === -1) {
        state.hiddenGenres.push(genre);
      } else {
        state.hiddenGenres.splice(index, 1);
      }
    },
    resetGenres: (state) => {
      state.genres = [];
      state.hiddenGenres = [];
    },
    setBrowseMode: (state, action) => {
      state.browseMode = action.payload;
    },
    setBrowseModeGenre: (state, action) => {
      state.browseModeGenre = action.payload;
    },
    setBrowseModeCountry: (state, action) => {
      state.browseModeCountry = action.payload;
    },
    setShowBrowseGrid: (state, action) => {
      state.showBrowseGrid = action.payload;
    },
    resetBrowseMode: (state) => {
      state.browseModeCountry = initialState.browseModeCountry;
      state.browseModeGenre = initialState.browseModeGenre;
    },
    setOnlyFavoritesForDemo: (state, action) => {
      state.isOnlyFavoritesForDemo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPodcasts.pending, (state) => {
        state.podcasts.loading = true;
      })
      .addCase(fetchPodcasts.fulfilled, (state, action) => {
        state.podcasts.loading = false;
        state.podcasts.data = action.payload;
      })
      .addCase(fetchPodcasts.rejected, (state, action) => {
        state.podcasts.loading = false;
        state.podcasts.error = action.error.message;
      })
      .addCase(fetchTopPodcasts.pending, (state) => {
        state.topPodcasts.loading = true;
      })
      .addCase(fetchTopPodcasts.fulfilled, (state, action) => {
        state.topPodcasts.loading = false;
        state.topPodcasts.data = action.payload.topPodcasts;
        state.episodes = action.payload.episodes;
      })
      .addCase(fetchTopPodcasts.rejected, (state, action) => {
        state.topPodcasts.loading = false;
        state.topPodcasts.error = action.error.message;
      })
      .addCase(fetchPodcastsByGenre.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPodcastsByGenre.fulfilled, (state, action) => {
        state.status = 'success';
        const genre = action.meta.arg.genre;
        state.podcastsByGenre[genre] = action.payload;
      })
      .addCase(fetchPodcastsByGenre.rejected, (state, action) => {
        state.status = 'error';
        state.error = action.error.message;
      })
      .addCase(loadPodcastCharts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(loadPodcastCharts.fulfilled, (state, action) => {
        state.status = 'success';
        state.currentPodcast = action.payload;
      })
      .addCase(loadPodcastCharts.rejected, (state, action) => {
        state.status = 'error';
        state.error = action.error.message;
      })
      .addCase(fetchTopPodcastsForDemo.pending, (state) => {
        state.podcasts.loading = true;
      })
      .addCase(fetchTopPodcastsForDemo.fulfilled, (state, action) => {
        state.podcasts.loading = false;
        state.demoModePodcasts = action.payload.topPodcasts;
      })
      .addCase(fetchTopPodcastsForDemo.rejected, (state, action) => {
        state.podcasts.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchGenres.pending, (state) => {
        state.allGenres.loading = true;
      })
      .addCase(fetchGenres.fulfilled, (state, action) => {
        state.allGenres.loading = false;
        state.allGenres.data = action.payload;
      })
      .addCase(fetchGenres.rejected, (state, action) => {
        state.allGenres.loading = false;
        state.allGenres.error = action.error.message;
      })
      .addCase(setFavorites.fulfilled, (state, action) => {
        state.favorites = action.payload;
      });
  },
});

export const {
  setCurrentPodcast,
  resetPodcasts,
  setSelectedGenre,
  setSelectedCountry,
  resetPodcastsByGenre,
  setDateRange,
  resetDateRange,
  setGenres,
  toggleHiddenGenre,
  resetGenres,
  setBrowseMode,
  setBrowseModeGenre,
  setBrowseModeCountry,
  setShowBrowseGrid,
  resetBrowseMode,
  resetDemoModePodcasts,
  setOnlyFavoritesForDemo,
} = podcastsSlice.actions;

export default podcastsSlice.reducer;
