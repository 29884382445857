import axios from 'axios';

export const sendEmail = async (data) => {
  const response = await axios.post('/api/top-podcasts/email-notify', data);
  return response.data;
};

export const unsubscribe = async (data) => {
  const response = await axios.post('/api/top-podcasts/unsubscribe', data);
  return response.data;
};
