import './App.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Charts } from './pages/Charts';
import { Unsubscribe } from './pages/Unsubscribe';

function App() {
  return (
    <div className="App">
      <BrowserRouter
        future={{
          v7_startTransition: true,
          v7_relativeSplatPath: true,
        }}
      >
        <Routes>
          <Route path="/" element={<Charts />} />
          <Route path="/unsubscribe" element={<Unsubscribe />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
