import React, { useCallback, useState } from 'react';
import { Box, Button, CircularProgress, Collapse, Typography } from '@mui/material';
import bgBtn from '../../assets/images/btn.png';
import { useSelector } from 'react-redux';
import { useStyles } from './styles';

export const PodcastCharts = () => {
  const classes = useStyles({ bgBtn });
  const [expanded, setExpanded] = useState(false);
  const {
    topPodcasts: { data, loading },
  } = useSelector((state) => state.podcasts);

  const getDiffRank = (ranks) => {
    if (ranks.length < 2) return '';
    const [prev, curr] = ranks.slice(-2);
    const diffRank = +prev.rank - +curr.rank;
    if (diffRank === 0) return '';
    return diffRank < 0 ? `(down from #${prev.rank})` : `(up from #${prev.rank})`;
  };

  const handleChange = useCallback(() => {
    setExpanded((prevExpanded) => !prevExpanded);
  }, []);

  const renderCharts = useCallback(() => {
    if (!data?.length) return <></>;
    const sortedClone = structuredClone(data).sort((a, b) => {
      if (a.country === 'United States' && b.country !== 'United States') return -1;
      if (a.country !== 'United States' && b.country === 'United States') return 1;

      const rankA = a.historyRank[a.historyRank.length - 1].rank;
      const rankB = b.historyRank[b.historyRank.length - 1].rank;
      return rankA - rankB;
    });
    const firsts = sortedClone.slice(0, 6);
    const rest = sortedClone.slice(6);

    const renderBox = (item) => (
      <Box mb="10px" key={item._id}>
        <Typography className={classes.chartItem}>
          #{item.rank} in {item.genre} in {item.country} {getDiffRank(item.historyRank)}
        </Typography>
      </Box>
    );

    const visible = firsts.map(renderBox);
    const hidden = rest.map(renderBox);
    return (
      <>
        <Box className={classes.chartContainer}>{visible}</Box>
        {hidden.length > 0 && (
          <Box display="flex" flexDirection="column" width="100%">
            <Collapse in={expanded}>
              <Box className={classes.chartContainer}>{hidden}</Box>
            </Collapse>
            <Button className={classes.showMoreButton} onClick={handleChange}>
              {expanded ? 'Hide' : 'Show More'}
            </Button>
          </Box>
        )}
      </>
    );
  }, [data, expanded, classes.chartContainer, classes.chartItem, classes.showMoreButton, handleChange]);

  return (
    <>
      {loading ? (
        <Box display="flex" justifyContent="center" width="100%">
          <CircularProgress />
        </Box>
      ) : (
        <Box width="100%">{renderCharts()}</Box>
      )}
    </>
  );
};
