import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './styles';

const LastRank = ({ num, element }) => {
  const classes = useStyles();
  const { x = 0, y = 0 } = element;
  const tooltipStyle = {
    transform: `translate(${x - 25}px, ${y - 45}px)`,
  };
  
  return (
    <div className={classes.container} style={tooltipStyle}>
      <span className={classes.num}>{num}</span>
    </div>
  );
};

LastRank.propTypes = {
  num: PropTypes.number.isRequired,
  element: PropTypes.oneOf(['top', 'left']).isRequired,
};

export default LastRank; 