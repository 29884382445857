import { Box, FormControl, Select, MenuItem } from '@mui/material';
import { useStyles, menuProps } from './styles';
import { countries } from '../../const';
import { useDispatch, useSelector } from 'react-redux';
import { setBrowseModeCountry, setBrowseModeGenre } from '../../redux/slices/podcastsSlice';
import { useRef } from 'react';

export const BrowseChartsFilters = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { browseModeCountry, browseModeGenre, allGenres: { data: genres } } = useSelector((state) => state.podcasts);
  const dummyRef = useRef(null);

  const handleCountryChange = (event) => {
    dispatch(setBrowseModeCountry(event.target.value));
    setTimeout(() => {
      dummyRef.current?.focus();
    }, 0);
  };

  const handleGenreChange = (event) => {
    dispatch(setBrowseModeGenre(event.target.value));
    setTimeout(() => {
      dummyRef.current?.focus();
    }, 0);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
      event.preventDefault();
      event.stopPropagation();
      dummyRef.current?.focus();
    }
  };

  return (
    <Box className={classes.filters}>
      <div 
        ref={dummyRef} 
        tabIndex={-1} 
        style={{ position: 'absolute', opacity: 0, pointerEvents: 'none' }}
      />
      
      <FormControl>
        <Select
          value={browseModeCountry}
          onChange={handleCountryChange}
          className={classes.select}
          MenuProps={menuProps}
          onKeyDown={handleKeyDown}
        >
          {countries.map((country) => (
            <MenuItem key={country} value={country}>
              {country}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl>
        <Select
          value={browseModeGenre}
          onChange={handleGenreChange}
          className={classes.select}
          MenuProps={menuProps}
          onKeyDown={handleKeyDown}
        >
          {genres.map((genre) => (
            <MenuItem key={genre} value={genre}>
              {genre}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
