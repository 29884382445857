import moment from 'moment';

function getRandomBrightHexColor() {
  const r = Math.floor(Math.random() * 156) + 100; // Minimum value of 100 for red
  const g = Math.floor(Math.random() * 156) + 100; // Minimum value of 100 for green
  const b = Math.floor(Math.random() * 156) + 100; // Minimum value of 100 for blue
  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
}

export const getSetupTopPodcastsChart = (data, chosenCountry, activeRange = '60d', episodes) => {
  const daysToShow = parseInt(activeRange);

  const cutoffDate = moment().subtract(daysToShow, 'days');

  const filteredData = data
    .filter(({ country }) => country === chosenCountry)
    .map((item) => ({
      ...item,
      historyRank: item.historyRank.filter((rank) => moment(rank.date).isAfter(cutoffDate)),
    }));

  const labels = [
    ...new Set([
      ...filteredData
        .map(({ historyRank }) => historyRank)
        .flat()
        .sort((a, b) => moment(a.date).diff(b.date))
        .map(({ date }) => moment(date).format('MMM DD')),
    ]),
  ];

  const colors = ['#de5a35', '#eda440', '#4896c0'];

  // Sort the filtered data to ensure "Top podcasts" comes first
  const sortedData = [...filteredData].sort((a, b) => b.historyRank.length - a.historyRank.length);

  // Get unique sorted genres with "Top Podcasts" first
  const genres = [
    ...new Set(
      sortedData.map((item, i) => {
        if (!colors[i]) colors[i] = getRandomBrightHexColor();
        return {
          name: item.genre,
          color: colors[i],
        };
      })
    ),
  ].sort((a, b) => {
    if (a.name === 'Top Podcasts') return -1;
    if (b.name === 'Top Podcasts') return 1;
    return 0;
  });

  let episodeIsAdded = false;
  const chartData = sortedData.reduce(
    (accum, item, i) => {
      const color = colors.shift();
      if (!item) {
        return accum;
      }
      const dataPoints = item.historyRank.map(({ rank, date }, i) => {
        let matchingEpisode = null;
        if (!episodeIsAdded && !item.hidden) {
          matchingEpisode = episodes?.find(
            (episode) =>
              moment(episode.pubDate).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD')
          );
        }
        return {
          x: moment(date).format('MMM DD'),
          y: rank,
          episode: matchingEpisode,
          isLastRank: item.historyRank.length - 1 === i && !item.hidden && !episodeIsAdded,
          podcast: !item.hidden
            ? {
                name: item.name,
                rank: rank,
                prevRank: item.historyRank[i - 1]?.rank || null,
                images: item.images,
                genre: item.genre,
                country: item.country,
              }
            : null,
        };
      });

      if (dataPoints.length > 0) {
        accum.datasets.push({
          label: item.genre,
          data: dataPoints,
          backgroundColor: color,
          borderColor: color,
          hidden: !!item.hidden,
        });
        episodeIsAdded = episodeIsAdded || !item.hidden;
      }

      return accum;
    },
    {
      labels,
      datasets: [],
      genres,
    }
  );

  const maxLength = chartData.datasets.reduce(
    (max, { data }) => Math.max(max, data?.length || 0),
    0
  );
  const longestDataset = chartData.datasets.find((dataset) => dataset.data.length === maxLength);

  if (maxLength > 10 && chartData.datasets.length > 0) {
    const paddingCount = Math.round(maxLength / 10) + 1;

    chartData.datasets = chartData.datasets.map((dataset) => {
      if (!dataset.data?.length) return;
      const firstDate = moment(dataset.data[0].x);
      const lastDate = moment(dataset.data[dataset.data.length - 1].x);
      const longestFirstDate = moment(longestDataset.data[0].x);
      const longestLastDate = moment(longestDataset.data[longestDataset.data.length - 1].x);

      let startPadding = [];
      let endPadding = [];

      // Only add padding if dates match longest dataset
      if (firstDate.isSame(longestFirstDate, 'day')) {
        startPadding = Array(paddingCount)
          .fill()
          .map((_, i) => {
            const firstRealY = +dataset.data[0].y;
            // Small random variation around the first real value
            const variation = Math.floor(Math.random() * 5) - 2; // Random number between -2 and 2
            return {
              x: `${firstDate
                .clone()
                .subtract(paddingCount - i, 'days')
                .format('MMM DD')} : f`,
              y: firstRealY + variation,
            };
          });
      }

      if (lastDate.isSame(longestLastDate, 'day')) {
        endPadding = Array(paddingCount)
          .fill()
          .map((_, i) => {
            const lastRealY = +dataset.data[dataset.data.length - 1].y;
            // Small random variation around the last real value
            const variation = Math.floor(Math.random() * 5) - 2; // Random number between -2 and 2
            return {
              x: `${lastDate
                .clone()
                .add(i + 1, 'days')
                .format('MMM DD')} : f`,
              y: lastRealY + variation,
            };
          });
      }

      return {
        ...dataset,
        data: [...startPadding, ...dataset.data, ...endPadding],
      };
    });

    // Update labels to include padding dates
    const firstLabel = moment(chartData.labels[0]);
    const lastLabel = moment(chartData.labels[chartData.labels.length - 1]);

    const startLabels = Array(paddingCount)
      .fill()
      .map(
        (_, i) =>
          `${firstLabel
            .clone()
            .subtract(paddingCount - i, 'days')
            .format('MMM DD')} : f`
      );

    const endLabels = Array(paddingCount)
      .fill()
      .map(
        (_, i) =>
          `${lastLabel
            .clone()
            .add(i + 1, 'days')
            .format('MMM DD')} : f`
      );

    chartData.labels = [...startLabels, ...chartData.labels, ...endLabels];
  }

  return chartData;
};

export const findCountryWithTodayData = (data, sortedCountries, isDemoMode = false) => {
  if (!isDemoMode) {
    return sortedCountries[0];
  }

  const usData = data.find((item) => item.country === 'United States');
  if (usData) {
    const lastHistoryDate = usData.historyRank[usData.historyRank.length - 1].date;
    const isUSToday = moment(lastHistoryDate).isSame(moment(), 'day');

    if (isUSToday) {
      return 'United States';
    }
  }

  const todayData = data.find((item) => {
    if (item.country === 'United States') return false;
    const lastHistoryDate = item.historyRank[item.historyRank.length - 1].date;
    return moment(lastHistoryDate).isSame(moment(), 'day');
  });

  if (todayData) {
    return todayData.country;
  }

  return sortedCountries[0];
};
