import { Box, Link, Typography } from '@mui/material';
import cx from 'classnames';
import { useState } from 'react';
import { ReactComponent as YoutubeView } from '../../assets/images/footer-text-1.svg';
import { ReactComponent as MowpodSub } from '../../assets/images/footer-text-2.svg';
import { ReactComponent as MowpodBoostPro } from '../../assets/images/footer-text-3.svg';
import { ReactComponent as BoostSelfServe } from '../../assets/images/footer-text-4.svg';
import { ReactComponent as Boost } from '../../assets/images/footer-text-5.svg';
import { FooterModal } from '../FooterModal/FooterModal';
import { useStyles } from './styles';

export const Footer = () => {
  const classes = useStyles();
  const currentYear = new Date().getFullYear();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedLogo, setSelectedLogo] = useState(null);
  const [selectedLink, setSelectedLink] = useState('');

  const handleLinkClick = (e, Logo, linkId) => {
    e.preventDefault();
    setSelectedLogo(Logo);
    setSelectedLink(linkId);
    setModalOpen(true);
  };

  return (
    <Box className={classes.footer}>
      <Box className={classes.footerLinks}>
        <Box className={classes.footerRow}>
          <Link
            href="#"
            className={cx(classes.footerLink, classes.mowsub)}
            onClick={(e) => handleLinkClick(e, MowpodSub, 'mowsub')}
          >
            <MowpodSub />
          </Link>
          <span className={classes.dot}>•</span>
          <Link
            href="#"
            className={cx(classes.footerLink, classes.mowboost)}
            onClick={(e) => handleLinkClick(e, MowpodBoostPro, 'mowboost')}
          >
            <MowpodBoostPro />
          </Link>
        </Box>

        <Box className={classes.footerRow}>
          <Link
            href="#"
            className={cx(classes.footerLink, classes.youtube)}
            onClick={(e) => handleLinkClick(e, YoutubeView, 'youtube')}
          >
            <YoutubeView />
          </Link>
          <span className={classes.dot}>•</span>
          <Link
            href="#"
            className={classes.footerLink}
            onClick={(e) => handleLinkClick(e, BoostSelfServe, 'selfserve')}
          >
            <BoostSelfServe />
          </Link>
          <span className={classes.dot}>•</span>
          <Link href="#" className={classes.footerLink} onClick={(e) => handleLinkClick(e, Boost, 'boost')}>
            <Boost />
          </Link>
        </Box>
      </Box>
      <Typography className={classes.copyright}>
        © 2019-{currentYear}{' '}
        <Link href="https://mowmedia.com" target="_blank" className={classes.link}>
          mowMedia LLC
        </Link>
        . All rights reserved.
      </Typography>

      <FooterModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        logo={selectedLogo}
        linkId={selectedLink}
      />
    </Box>
  );
};
