import { makeStyles } from '@mui/styles';
import episodeBg from '../../assets/images/episode-bg.svg';

export const useStyles = makeStyles((theme) => ({
  episode: {
    zIndex: 1,
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: 100,
    height: 30,
    padding: '2px 50px 13px 20px',
    borderRadius: '8px',
    backgroundColor: 'transparent',
    backgroundImage: `url(${episodeBg})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    overflow: 'hidden',
  },
  title: {
    fontSize: 10,
    fontWeight: 500,
    color: '#fff',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  date: {
    fontSize: 10,
    color: '#fff',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  svgColor: {
    '& path': {
      fill: '#fff',
    },
  },
}));


