import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  chartContainer: {
    width: '100%',
    margin: '0 auto',
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridAutoRows: 'auto',
    gap: '5px 30px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      gridTemplateColumns: '1fr',
    },
    [theme.breakpoints.up('lg')]: {
      width: '85%',
    },
    [theme.breakpoints.up('xl')]: {
      width: '70%',
    },
  },
  chartItem: {
    backgroundColor: 'transparent',
    border: '0.5px solid grey',
    borderRadius: '10px',
    color: 'white',
    textAlign: 'left',
    margin: '5px 0',
    padding: '5px 10px',
  },
  showMoreButton: {
    width: 190,
    color: '#fff !important',
    fontWeight: '900 !important',
    height: 40,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    marginTop: '10px !important',
    backgroundImage: (props) => `url("${props.bgBtn}")`,
    margin: '0 auto !important',
    '&:hover': {
      filter: 'brightness(120%)',
      boxShadow: 'none',
      backgroundColor: 'rgba(255, 255, 128, 0)',
      transform: 'scale(1.05) rotate(-2.5deg)',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));
