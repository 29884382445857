import { useSelector, useDispatch } from 'react-redux';
import { Box, Typography, Tooltip, IconButton } from '@mui/material';
import cx from 'classnames';
import { useStyles } from './styles';
import { Fragment, useEffect, useState } from 'react';
import {
  setSelectedGenre,
  fetchPodcastReleaseDate,
  setFavorites,
  fetchPodcastsByGenre,
} from '../../redux/slices/podcastsSlice';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { PlatformsAndSocials } from '../PlatformsAndSocials/PlatformsAndSocials';

export const PodcastInfo = () => {
  const classes = useStyles();
  const { currentPodcast, selectedGenre, genres, favorites, selectedCountry, podcastsByGenre, status } =
    useSelector((state) => state.podcasts);
  const dispatch = useDispatch();
  const isFavorite = favorites.some(fav => fav.podcastId === currentPodcast?.collectionId);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = date.toLocaleString('en-US', { month: 'short' });
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month} ${day} ${year}`;
  };

  const [releaseDate, setReleaseDate] = useState(null);

  useEffect(() => {
    if (currentPodcast) {
      dispatch(fetchPodcastReleaseDate(currentPodcast.feedUrl)).then((response) => {
        setReleaseDate(response.payload);
      });
    }
  }, [currentPodcast, dispatch]);

  useEffect(() => {
    if (genres.length > 0) {
      genres.forEach((genre) => {
        dispatch(
          fetchPodcastsByGenre({
            genre: genre.name,
            country: selectedCountry,
          })
        );
      });
    }
  }, [genres, dispatch, selectedCountry]);

  if (!currentPodcast) return null;

  return (
    <Box className={classes.podcastInfo}>
      <Box className={classes.podcastInfoWrapper}>
        <img src={currentPodcast.artworkUrl60} alt={currentPodcast.name} className={classes.podcastImage} />
        <Box className={classes.podcastDetailsWrapper}>
          <Box className={classes.podcastTitle}>
            <Typography className={classes.podcastName}>
              {currentPodcast.collectionName}
              <Tooltip
                title={isFavorite ? 'Remove from favorites' : 'Add to favorites'}
                placement="top"
                arrow
              >
                <IconButton
                  onClick={() => dispatch(setFavorites({
                    podcastId: currentPodcast.collectionId,
                    name: currentPodcast.collectionName
                  }))}
                  className={classes.favoriteButton}
                >
                  {isFavorite ? (
                    <FavoriteIcon className={classes.favoriteIcon} />
                  ) : (
                    <FavoriteBorderIcon className={classes.favoriteIcon} />
                  )}
                </IconButton>
              </Tooltip>
            </Typography>
            <PlatformsAndSocials />
          </Box>
          <Box className={classes.podcastDetails}>
            <Typography className={classes.podcastInfoText}>
              Released on {releaseDate ? formatDate(releaseDate) : '...'}
            </Typography>
            <Typography className={classes.podcastEpisodes}>{currentPodcast.trackCount} episodes</Typography>
            <Typography className={classes.podcastCategories}>
              charting on Apple in:{' '}
              {currentPodcast.genres && currentPodcast.genres.length > 0 && (
                <span>
                  {currentPodcast.genres.map((genre, index) => {
                    const correctGenre = genre === 'Podcasts' ? 'Top Podcasts' : genre;
                    const isGenreAvailable =
                      genres.some((g) => g.name === correctGenre) &&
                      podcastsByGenre[correctGenre]?.length > 0;
                    const isLoading = status === 'loading';

                    return (
                      <Fragment key={index}>
                        <span
                          className={cx(classes.categoryText, {
                            selected: selectedGenre === correctGenre,
                            clickable: isGenreAvailable && !isLoading,
                            loading: isLoading && genres.some((g) => g.name === correctGenre),
                          })}
                          onClick={() => isGenreAvailable && dispatch(setSelectedGenre(correctGenre))}
                        >
                          {correctGenre}
                          {isLoading && genres.some((g) => g.name === correctGenre) && '...'}
                        </span>
                        {index < currentPodcast.genres.length - 1 && (
                          <span className={classes.divider}> | </span>
                        )}
                      </Fragment>
                    );
                  })}
                </span>
              )}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
