import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  footer: {
    padding: '20px 15px',
    textAlign: 'center',
    marginTop: 'auto',
  },
  footerLinks: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 10,
    margin: '0 auto 20px',
    [theme.breakpoints.down(430)]: {
      gap: 0,
    },
  },
  footerRow: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
    justifyContent: 'center',
    flexWrap: 'wrap',
    [theme.breakpoints.down(430)]: {
      flexDirection: 'column',
    },
  },
  footerLink: {
    textDecoration: 'none !important',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      opacity: 0.8,
    },
    '& svg': {
      height: 24,
      width: 'auto',
    },
    [theme.breakpoints.down(430)]: {
      padding: 5,
    },
  },
  mowsub: {
    '& svg': {
      height: 26,
      paddingTop: 5,
    },
  },
  mowboost: {
    '& svg': {
      height: 28,
      paddingTop: 2,
    },
  },
  youtube: {
    '& svg': {
      height: 29,
      paddingTop: 5,
    },
  },
  dot: {
    color: '#f66000',
    fontSize: 35,
    lineHeight: 0,
    [theme.breakpoints.down(430)]: {
      display: 'none',
    },
  },
  copyright: {
    color: '#fff',
    fontSize: 16,
    fontWeight: 500,
  },
  link: {
    color: '#46c0ec !important',
    textDecoration: 'none !important',
    '&:hover': {
      textDecoration: 'underline !important',
    },
  },
}));
