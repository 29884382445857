import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  modal: {
    '& .MuiDialog-paper': {
      backgroundColor: '#1a1a1a',
      borderRadius: 10,
      padding: '35px 30px 30px',
      maxWidth: 630,
      width: '100%',
    },
    '& $closeButton': {
      position: 'absolute',
      right: 8,
      top: 8,
      color: '#fff',
      '&:hover': {
        backgroundColor: 'transparent',
        '& svg': {
          color: '#f66000',
        },
      },
    },
  },
  title: {
    color: '#fff',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '26px',
    textAlign: 'center',
    whiteSpace: 'pre-line',
  },
  button: {
    width: 'max-content',
    color: '#fff !important',
    fontWeight: '900 !important',
    height: 90,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundImage: (props) => `url("${props.bgBtn}")`,
    margin: '30px auto 0 !important',
    '&:hover': {
      filter: 'brightness(120%)',
      boxShadow: 'none',
      backgroundColor: 'rgba(255, 255, 128, 0)',
      transform: 'scale(1.05) rotate(-2.5deg)',
    },
  },
  link: {
    margin: '0 auto !important',
  },
  logoWrapper: {
    display: 'flex',
    justifyContent: 'center',
    margin: '10px 0 20px',
    '& svg': {
      height: 35,
      width: 'auto',
    },
  },
  closeButton: {},
  calendlyWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    '& iframe': {
      borderRadius: 8,
      backgroundColor: '#fff',
    },
  },
}));
