import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: '16px 150px',
    [theme.breakpoints.down('sm')]: {
      padding: '16px 16px 150px',
    },
    [theme.breakpoints.down(430)]: {
      padding: '16px 16px 210px',
    },
    position: 'absolute',
    top: 210,
    zIndex: 1000,
    backdropFilter: 'blur(2px)',
    backgroundColor: 'rgba(0, 0, 0, 0.01)',
    left: 0,
    right: 0,
    minHeight: '100vh',
    paddingBottom: 120,
  },
  filters: {
    display: 'flex',
    gap: 8,
    zIndex: 1001,
  },
  select: {
    borderRadius: '15px !important',
    minWidth: 178,
    '& .MuiSelect-select': {
      padding: 13,
    },
    '& .MuiSvgIcon-root': {
      color: 'white',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: '1px solid grey',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: '1px solid white !important',
    },
  },
  pagination: {
    '& .MuiPaginationItem-root': {
      color: '#fff',
      '&.Mui-selected': {
        backgroundColor: '#f66000',
      },
      '&:hover': {
        backgroundColor: 'rgba(246, 96, 0, 0.2)',
      },
    },
  },
  pageButton: {
    color: '#fff !important',
    '&.Mui-disabled': {
      color: 'rgba(255, 255, 255, 0.3) !important',
    },
    '&:hover': {
      backgroundColor: 'rgba(246, 96, 0, 0.2) !important',
    },
  },
  closeButtonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 16,
    marginRight: -8,
  },
  closeButton: {
    color: '#f66000 !important',
    '&:hover': {
      backgroundColor: 'rgba(246, 96, 0, 0.1) !important',
    },
  },
}));

export const menuProps = {
  PaperProps: {
    sx: {
      borderRadius: 2,
      maxHeight: 400,
      marginTop: '5px',
      '& .MuiMenuItem-root': {
        color: '#fff',
        fontSize: 14,
        fontFamily: 'Montserrat',
        '&:hover': {
          bgcolor: 'rgba(246, 96, 0, 0.1)',
        },
        '&.Mui-selected': {
          bgcolor: 'rgba(246, 96, 0, 0.2)',
          '&:hover': {
            bgcolor: 'rgba(246, 96, 0, 0.3)',
          },
        },
      },
    },
  },
};
