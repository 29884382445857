export const options = {
  responsive: true,
  aspectRatio: 5,
  interaction: {
    mode: 'point',
    axis: 'xy',
    intersect: false,
  },
  plugins: {
    tooltip: {
      enabled: false,
    },
    legend: {
      display: false,
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      min: 1,
      max: 200,
      reverse: true, // Reverse the y-axis
      grid: {
        color: '#292929', // Set grid color for y-axis
      },
      offset: true,
      grace: 50,
      ticks: {
        color: '#b4b4b4',
        // stepSize: 25, // Set step size for y-axis
      },
    },
    x: {
      grid: {
        color: '#292929', // Set grid color for x-axis
      },
      ticks: {
        beginAtZero: true,
        color: '#b4b4b4',
        align: 'center',
        callback: function (value) {
          const tick = this.getLabelForValue(value);
          return tick.split(' : ')[1] ? '': tick;
        },
        offset: true,
      },
    },
  },
};

export const initialState = {
  labels: [],
  datasets: [
    {
      label: '',
      backgroundColor: [],
      data: [],
      hoverOffset: 4,
    },
  ],
};