import React from 'react';
import { Box, Typography } from '@mui/material';
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';
import { useStyles } from './styles';

const PodcastTooltip = ({ element = {}, podcast = {} }) => {
  const classes = useStyles();

  if (!element || !podcast) return null;
  const { x = 0, y = 0 } = element;
  const tooltipStyle = {
    transform: `translate(${x}px, ${y}px)`,
  };

  const calculateRankChange = ({ rank = 199, prevRank = 200 }) => {
    const difference = prevRank - rank;

    if (difference === 0 || !prevRank) {
      return (
        <>
          <span>—</span>
        </>
      );
    }

    return difference > 0 ? (
      <span className={classes.historyRankUp}>
        <NorthIcon fontSize="small" />
        {difference}
      </span>
    ) : (
      <span className={classes.historyRankDown}>
        <SouthIcon fontSize="small" />
        {-difference}
      </span>
    );
  };

  return (
    <Box className={classes.tooltip} style={tooltipStyle}>
      <Box className={classes.content}>
        <img src={podcast.images || 'https://placehold.co/60x60'} alt={podcast.name} className={classes.image} />
        <Box className={classes.info}>
          <Box className={classes.rankContainer}>
            <Typography className={classes.rank}>{podcast?.rank || 100}</Typography>
          </Box>
          <Box className={classes.historyRank}>
            {/* <Typography>{calculateRankChange(podcast)}</Typography> */}
            <Typography>{calculateRankChange(podcast)}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PodcastTooltip; 