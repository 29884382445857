import { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { sendEmail } from '../../api/email';
import bgBtn from '../../assets/images/btn-get-em.png';
import { Button, Box, CircularProgress, Typography } from '@mui/material';
import { useStyles } from './styles';
import { useSelector } from 'react-redux';

export const EmailNotify = () => {
  const classes = useStyles({ bgBtn });
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const { currentPodcast, browseMode } = useSelector((state) => state.podcasts);

  useEffect(() => {
    let timer;
    if (showSuccess) {
      timer = setTimeout(() => {
        setShowSuccess(false);
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [showSuccess]);

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    if (values.email.trim()) {
      setIsLoading(true);
      try {
        await sendEmail({ email: values.email, podcastId: currentPodcast.collectionId });
        resetForm();
        setShowSuccess(true);
      } catch (error) {
        console.error('Error sending email:', error);
      } finally {
        setIsLoading(false);
        setSubmitting(false);
      }
    }
  };

  return (
    <Box className={classes.notifyWrapper}>
      <Formik initialValues={{ email: '' }} onSubmit={handleSubmit}>
        {({ handleSubmit }) => (
          <Form>
            <Box className={classes.notifyForm} sx={{ position: 'relative' }}>
              <Typography variant="h7" component="h2" className={classes.notifyTitle}>
                Get daily notifications on this podcast
              </Typography>
              <Box className={classes.notifyControls}>
                <Field
                  name="email"
                  type="email"
                  placeholder="Your email"
                  disabled={!currentPodcast || browseMode}
                  className={classes.notifyInput}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      handleSubmit();
                    }
                  }}
                />
                <Button type="submit" disabled={isLoading || !currentPodcast} className={classes.notifyButton}>
                  {isLoading ? <CircularProgress size={24} color="inherit" /> : ''}
                </Button>
              </Box>
              {showSuccess && (
                <Typography
                  sx={{
                    color: 'green',
                    position: 'absolute',
                    top: '80px',
                    opacity: 1,
                    fontWeight: 600,
                    transition: 'opacity 0.3s ease-in-out',
                    padding: '8px 16px',
                    zIndex: 1,
                  }}
                >
                  Added!
                </Typography>
              )}
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
