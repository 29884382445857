import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  chart: {
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      minWidth: '1200px',
      width: '1200px',
      '& canvas': {
        minWidth: '1200px',
        width: '1200px',
      }
    }
  },
  chartWrapper: {
    position: 'relative',
    minHeight: 500,
    [theme.breakpoints.up('md')]: {
      minHeight: 325,
    },
    [theme.breakpoints.up('lg')]: {
      minHeight: 380,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    }
  },
  chartWrapperNoGenre: {
    [theme.breakpoints.down('md')]: {
      minHeight: 'unset',
      overflowX: 'auto',
      overflowY: 'hidden',
      WebkitOverflowScrolling: 'touch',
    }
  },
  carouselBackdrop: {
    position: 'absolute',
    [theme.breakpoints.up('md')]: {
      top: '5%',
    },
    [theme.breakpoints.up('lg')]: {
      top: '-10%',
    },
    [theme.breakpoints.up('xl')]: {
      top: '-19%',
    },
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    opacity: 0,
    animation: '$fadeIn 0.2s ease-in-out forwards',
    [theme.breakpoints.down('md')]: {
      minHeight: 500,
    },
  },
  chartContent: {
    transition: 'all 0.3s ease-in-out',
    [theme.breakpoints.down('md')]: {
      width: '1200px',
    }
  },
  blurred: {
    filter: 'blur(3px)',
    transform: 'scale(0.99)',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 150px 15px',
    [theme.breakpoints.down('md')]: {
      padding: '0 15px 15px',
    },
  },
  blur: {
    position: 'absolute',
    width: '9%',
  },
  startBlur: {
    left: 0,
    background: 'linear-gradient(90deg, rgb(30 29 29) 0%, rgba(0, 0, 0, 0) 100%)',
  },
  endBlur: {
    right: 0,
    background: 'linear-gradient(270deg, rgb(30 29 29) 0%, rgba(0, 0, 0, 0) 100%)',
  },
  select: {
    borderRadius: '15px !important',
    minWidth: 178,
    '& .MuiSelect-select': {
      padding: 13,
    },
    '& .MuiSvgIcon-root': {
      color: 'white',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: '1px solid grey',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: '1px solid white !important',
    },
  },
  svgColor: {
    '& path': {
      fill: ({ colorPoint }) => colorPoint,
    },
  },
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
      transform: 'translateY(-10px)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateY(0)',
    },
  },
}));
