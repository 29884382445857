import { Box, Dialog, IconButton, Typography, Button, Link } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import bgBtn from '../../assets/images/btn.png';
import { useStyles } from './styles';
import { modalText } from '../../const';
import { InlineWidget } from 'react-calendly';
import { useState } from 'react';

export const FooterModal = ({ open, onClose, logo: Logo, linkId }) => {
  const classes = useStyles({ bgBtn });
  const content = modalText[linkId] || {};
  const [showCalendly, setShowCalendly] = useState(false);

  const handleCalendlyClick = () => {
    setShowCalendly(true);
  };

  const handleClose = () => {
    setShowCalendly(false);
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} className={classes.modal}>
      <IconButton onClick={handleClose} className={classes.closeButton}>
        <CloseIcon />
      </IconButton>
      <Box className={classes.logoWrapper}>{Logo && <Logo />}</Box>
      {!showCalendly && (
        <Typography variant="p" className={classes.title}>
          {content?.text}
        </Typography>
      )}
      {linkId === 'selfserve' ? (
        <Link href={content?.link} target="_blank" className={classes.link}>
          <Button className={classes.button}>{content?.buttonText}</Button>
        </Link>
      ) : (
        <>
          {!showCalendly ? (
            <Button className={classes.button} onClick={handleCalendlyClick}>
              {content?.buttonText}
            </Button>
          ) : (
            <Box className={classes.calendlyWrapper}>
              <InlineWidget
                url="https://calendly.com/d/cpzp-c3g-vxf/mowpod-intro-chat?background_color=2a2a2a&text_color=ffffff&primary_color=f9a119"
                styles={{
                  minWidth: 320,
                  width: '100%',
                  height: 600,
                }}
              />
            </Box>
          )}
        </>
      )}
    </Dialog>
  );
};
