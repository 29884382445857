import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  navLink: {
    cursor: 'pointer',
    color: '#fff',
    opacity: 0.7,
    transition: 'opacity 0.3s',
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: '16px',
    '&:hover': {
      opacity: 1,
    },
  },
  searchTitle: {
    fontSize: 18,
    fontWeight: '400',
    paddingBottom: 15,
    color: 'white',
    fontFamily: 'Montserrat',
  },
  divider: {
    color: '#fff',
    opacity: 0.5,
  },
  favoritesPopper: {
    zIndex: 1301,
    marginTop: '-10px !important',
    '& .MuiPaper-root': {
      backgroundColor: '#1e1d1d',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)',
    },
  },
  favoritesPaper: {
    borderRadius: 10,
    minWidth: 200,
    padding: '12px 0',
    backdropFilter: 'blur(10px)',
    backgroundColor: '#2a2a2a !important',
  },
  favoritesList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  favoriteItem: {
    padding: '10px 20px !important',
    cursor: 'pointer',
    transition: 'all 0.2s ease',
    width: '100%',
    justifyContent: 'flex-start',
    height: 42,
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
    '& .MuiTypography-root': {
      color: '#fff',
      fontSize: 16,
      fontWeight: 500,
      transition: 'color 0.2s ease',
      textAlign: 'left',
    },
    '&:hover .MuiTypography-root': {
      color: '#fff',
    },
  },
  noFavorites: {
    padding: '10px 20px',
    color: '#fff',
    fontSize: 14,
    opacity: 0.6,
    fontStyle: 'italic',
  },
  rippleEffect: {
    color: '#fff',
    opacity: 0.4,
  },
  rotateAllButton: {
    width: '100%',
    justifyContent: 'center',
    padding: '10px 20px !important',
    borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
    marginBottom: 8,
    gap: 8,
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
    '& .MuiTypography-root': {
      color: '#f66000',
      fontSize: 14,
      fontWeight: 500,
    },
  },
  rotateIcon: {
    color: '#f66000',
    fontSize: 20,
  },
})); 