import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './styles';

const EpisodeTooltip = ({ num, title, element }) => {
  const classes = useStyles();
  return (
    <div 
      className={classes.episode}
      style={{
        top: element?.y - 50,
        left: element?.x - 10,
      }}
    >
      <div className={classes.date}>Episode {num}:</div>
      <div className={classes.title}>{title}</div>
    </div>
  );
};

EpisodeTooltip.propTypes = {
  date: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  element: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
};

export default EpisodeTooltip;
