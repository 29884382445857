import React, { useEffect, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import LensIcon from '@mui/icons-material/Lens';
import {
  Box,
  CircularProgress,
  FormControl,
  Grid2,
  MenuItem,
  Select,
  Typography,
  Button,
  ButtonGroup,
} from '@mui/material';
import cx from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { getSetupTopPodcastsChart, findCountryWithTodayData } from '../../utils/chart';
import { setSelectedCountry, setGenres, toggleHiddenGenre } from '../../redux/slices/podcastsSlice';
import PodcastsCarousel from '../PodcastsCarousel/PodcastsCarousel';
import point from '../../assets/images/point.svg';

import { useStyles } from './styles';
import EpisodeTooltip from '../EpisodeTooltip/EpisodeTooltip';
import PodcastTooltip from '../PodcastTooltip/PodcastTooltip';
import { initialState, options } from './chartConfig';
import LastRank from '../LastRank/LastRank';
// Register the necessary components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const SEVEN_DAYS = '7d';
const THIRTY_DAYS = '30d';
const SIXTY_DAYS = '60d';

const Chart = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [chartData, setChartData] = useState(initialState);
  const [countries, setCountries] = useState([]);
  const [activeRange, setActiveRange] = useState(THIRTY_DAYS);
  const [podcastTooltip, setPodcastTooltip] = useState(null);
  const [completeAnimation, setCompleteAnimation] = useState(false)
  const {
    currentPodcast,
    topPodcasts: { data, loading },
    selectedCountry: chosenCountry,
    selectedGenre,
    genres,
    hiddenGenres,
    demoModePodcasts,
    episodes,
  } = useSelector((state) => state.podcasts);
  const chart = useRef(null);
  const [episodeTooltip, setEpisodeTooltip] = useState();
  const [lastRankTooltip, setLastRankTooltip] = useState();
  const isFirstGenresSetup = useRef(true);

  const drawPoint = (ctx) => {
    if (!ctx?.raw) return;
    let image = null;
    if (ctx.raw.isLastRank) {
      setLastRankTooltip({lastRank: ctx.raw.y, element: ctx.element});
    }
    if (ctx.raw.episode) {
      image = new Image();
      image.src = point;
      image.width = '20';
      image.height = '20';
    }
    if (ctx.active && ctx.raw.episode) {
      setEpisodeTooltip({ episode: ctx.raw.episode, element: ctx.element });
    }

    if (ctx.active && ctx.raw.podcast) {
      setPodcastTooltip({
        podcast: { ...ctx.raw.podcast, images: currentPodcast.artworkUrl60 },
        element: ctx.element,
      });
    }

    return image;
  };

  useEffect(() => {
    if (!data?.length) return;
    const countries = [...new Set(data.map((item) => item.country))];
    const sortedCountries = countries.includes('United States')
      ? ['United States', ...countries.filter((country) => country !== 'United States')]
      : countries;

    if (sortedCountries.length) {
      const isDemoMode = demoModePodcasts.length > 0;
      const countryWithFreshData = findCountryWithTodayData(data, sortedCountries, isDemoMode);

      dispatch(setSelectedCountry(countryWithFreshData));
      setCountries(sortedCountries);
    }
  }, [data, demoModePodcasts, dispatch]);

  useEffect(() => {
    if (!data?.length) return;
    setCompleteAnimation(false);
    setLastRankTooltip(null);
    setEpisodeTooltip(null);
    setPodcastTooltip(null);

    try {
      const setup = getSetupTopPodcastsChart(
        data.map((item) => (!hiddenGenres.includes(item.genre) ? item : { ...item, hidden: true })),
        chosenCountry,
        activeRange,
        episodes
      );

      const numData =
        setup.datasets?.reduce((max, { data = [] }) => Math.max(max, data?.length || 0), 0) || 0;

      if (chart.current) {
        if (numData <= 10) {
          chart.current.options.scales.y.position = { x: 0 };
          chart.current.options.layout.padding.left = 150;
          chart.current.options.layout.padding.right = 150;
        } else {
          chart.current.options.scales.y.position = { x: Math.ceil(numData / 10) - numData / 100 };
          chart.current.options.layout.padding.left = 0;
          chart.current.options.layout.padding.right = 0;
        }
      chart.current.options.animation = {
        onComplete: () => {
          setCompleteAnimation(true);
        }
      };
      chart.current.options.elements.point.pointStyle = drawPoint;
      chart.current.options.onHover = (event, elements) => {
        if (!event) return;
        if (elements && elements.length > 0) {
          const element = elements[0];
          if (element?.raw?.podcast) {
            const rect = event.chart.canvas.getBoundingClientRect();
            const x = rect.left + element.element.x;
            const y = rect.top + element.element.y;
            element.element.x = x;
            element.element.y = y;
            setPodcastTooltip({ podcast: element.raw.podcast, element: element.element });
          }
        } else {
          setPodcastTooltip(null);
          setEpisodeTooltip(null);
        }
      };
      }

      if (isFirstGenresSetup.current) {
        dispatch(setGenres(setup.genres));
        isFirstGenresSetup.current = false;
      }

      setChartData(setup);
    } catch (error) {
      console.log(error);
    }
  }, [data, chosenCountry, dispatch, hiddenGenres, activeRange]);

  const handleChangeCountry = (event) => {
    const country = event.target.value;
    dispatch(setSelectedCountry(country));
  };

  const handleHideGenre = (name) => {
    dispatch(toggleHiddenGenre(name));
  };

  const handleDateRangeChange = (range) => {
    setActiveRange(range);
  };

  return data?.length && !loading ? (
    <Box className={cx(
      classes.chartWrapper, 
      { [classes.chartWrapperNoGenre]: !selectedGenre }
    )}>
      {selectedGenre && (
        <Box className={classes.carouselBackdrop}>
          <PodcastsCarousel />
        </Box>
      )}
      <Box className={cx(classes.chartContent, { [classes.blurred]: selectedGenre })}>
        <Grid2
          className={classes.header}
          container
          item
          md={12}
          spacing={1}
          justifyContent="space-between"
        >
          <Grid2 container item md={3} alignItems="center" spacing={2}>
            <Grid2 item>
              <FormControl variant="outlined">
                <Select
                  defaultValue={chosenCountry}
                  value={chosenCountry}
                  onChange={handleChangeCountry}
                  className={classes.select}
                >
                  {countries.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid2>
            <Grid2 item>
              <ButtonGroup variant="outlined" size="small">
                <Button
                  onClick={() => handleDateRangeChange(SEVEN_DAYS)}
                  variant={activeRange === SEVEN_DAYS ? 'contained' : 'outlined'}
                >
                  7d
                </Button>
                <Button
                  onClick={() => handleDateRangeChange(THIRTY_DAYS)}
                  variant={activeRange === THIRTY_DAYS ? 'contained' : 'outlined'}
                >
                  30d
                </Button>
                <Button
                  onClick={() => handleDateRangeChange(SIXTY_DAYS)}
                  variant={activeRange === SIXTY_DAYS ? 'contained' : 'outlined'}
                >
                  60d
                </Button>
              </ButtonGroup>
            </Grid2>
          </Grid2>
          <Grid2 container item md={9}>
            {genres.map(({ name, color }) => (
              <Grid2
                container
                item
                md={3}
                key={name}
                sx={{ cursor: 'pointer' }}
                onClick={() => handleHideGenre(name)}
              >
                <Box>
                  <LensIcon sx={{ color }} />
                </Box>
                <Box>
                  <Typography
                    sx={{
                      color: 'white',
                      textDecoration: hiddenGenres.includes(name) ? 'line-through' : 'none',
                    }}
                  >
                    {name}
                  </Typography>
                </Box>
              </Grid2>
            ))}
          </Grid2>
        </Grid2>
        <Grid2 className={classes.chart} container item md={12}>
          {completeAnimation && episodeTooltip && (
            <EpisodeTooltip
              key={episodeTooltip.episode.title}
              element={episodeTooltip.element}
              num={episodeTooltip.episode.numEpisode}
              title={episodeTooltip.episode.title}
            />
          )}
          {podcastTooltip && (
            <PodcastTooltip element={podcastTooltip?.element} podcast={podcastTooltip?.podcast} />
          )}
          {completeAnimation && lastRankTooltip && (
            <LastRank num={lastRankTooltip.lastRank} element={lastRankTooltip.element} />
          )}
          <div
            className={cx(classes.blur, classes.startBlur)}
            style={{ height: chart.current?.height }}
          />
          <Line ref={chart} data={chartData} options={options} />
          <div
            className={cx(classes.blur, classes.endBlur)}
            style={{ height: chart.current?.height }}
          />
        </Grid2>
      </Box>
    </Box>
  ) : (
    loading && <CircularProgress sx={{ margin: '0 auto' }} />
  );
};

export default Chart;
