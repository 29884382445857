import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  podcastInfo: {
    marginTop: 20,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
    padding: 16,
    borderRadius: 8,
    width: '100%',
    '& $podcastInfoText': {
      color: '#ffffff',
      fontSize: 16,
      fontWeight: 300,
    },
    '& $podcastImage': {
      borderRadius: 4,
      paddingTop: 10,
      [theme.breakpoints.down('sm')]: {
        width: 80,
        height: 80,
      },
    },
    '& $podcastName': {
      color: '#ffffff',
      fontSize: 24,
      fontWeight: 700,
    },
    '& $podcastTitle': {
      display: 'flex',
      alignItems: 'center',
      gap: '5px',
      marginBottom: 6,
      [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap',
        marginBottom: 15,
      },
    },
    '& $podcastEpisodes': {
      fontSize: 16,
      fontWeight: 500,
      color: '#ffffff',
    },
    '& $podcastCategories': {
      fontSize: 16,
      fontWeight: 300,
      color: '#ffffff',
      zIndex: 222,
    },
  },
  podcastInfoWrapper: {
    display: 'flex',
    gap: '16px',
    alignItems: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      gap: '25px',
    },
  },
  podcastDetails: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: 25,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  podcastDetailsWrapper: {
    display: 'block',
    textAlign: 'left',
  },
  categoryText: {
    cursor: 'default',
    color: '#ffffff',
    fontStyle: 'italic',
    transition: 'all 0.2s ease-in-out',
    '&.clickable': {
      cursor: 'pointer',
      color: '#46c0ec',
      textDecoration: 'underline',
      textUnderlineOffset: '2px',
      '&:hover': {
        filter: 'brightness(120%)',
        color: '#46c0ec',
      },
    },
    '&.selected': {
      color: '#46c0ec',
      fontWeight: 700,
    },
    '&.loading': {
      cursor: 'wait',
      opacity: 0.3,
    },
  },
  divider: {
    color: 'white',
  },
  favoriteButton: {
    '& svg': {
      width: '1.1em',
      height: '1.1em',
    },
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      '& svg': {
        transform: 'scale(1.1)',
      },
    },
  },
  favoriteIcon: {
    color: '#f66000',
    fontSize: '24px !important',
  },
  podcastInfoText: {},
  podcastImage: {},
  podcastTitle: {},
  podcastEpisodes: {},
  podcastCategories: {},
  podcastName: {},
}));
