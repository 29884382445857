import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Typography, Popper, Paper, ClickAwayListener, ButtonBase } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useStyles } from './styles';
import {
  setCurrentPodcast,
  setBrowseMode,
  setShowBrowseGrid,
  loadPodcastCharts,
  setSelectedGenre,
  resetPodcastsByGenre,
  resetBrowseMode,
  setOnlyFavoritesForDemo,
} from '../../redux/slices/podcastsSlice';
import { updatePodcastUrl } from '../../utils/updatePodcastUrl';
import AutorenewIcon from '@mui/icons-material/Autorenew';

export const FavoritesList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { favorites } = useSelector((state) => state.podcasts);
  const [favoritesOpen, setFavoritesOpen] = useState(false);
  const [favoritesAnchorEl, setFavoritesAnchorEl] = useState(null);

  const handleFavoritesMouseEnter = (event) => {
    setFavoritesAnchorEl(event.currentTarget);
    setFavoritesOpen(true);
  };

  const handleFavoritesMouseLeave = () => {
    setFavoritesOpen(false);
  };

  const closeAndReset = () => {
    dispatch(setSelectedGenre(null));
    dispatch(resetPodcastsByGenre());
    dispatch(setShowBrowseGrid(false));
    dispatch(setBrowseMode(false));
    dispatch(resetBrowseMode());
    setFavoritesOpen(false);
  };

  const handleFavoritePodcastClick = (favorite) => {
    dispatch(loadPodcastCharts(favorite)).then((response) => {
      updatePodcastUrl(favorite.podcastId);
      dispatch(setCurrentPodcast(response.payload));
    });
    closeAndReset();
  };

  const handleRotateAllClick = () => {
    dispatch(setOnlyFavoritesForDemo(false));
    setTimeout(() => {
      dispatch(setOnlyFavoritesForDemo(true));
      navigate('/');
      closeAndReset();
    }, 0);
  };

  return (
    <>
      <Box className={classes.divider}>|</Box>
      <Box
        className={classes.navLink}
        onMouseEnter={handleFavoritesMouseEnter}
        onMouseLeave={handleFavoritesMouseLeave}
      >
        <Typography variant="h7" component="h2" className={classes.searchTitle}>
          Fav Podcasts
        </Typography>
        <Popper
          open={favoritesOpen}
          anchorEl={favoritesAnchorEl}
          placement="bottom-start"
          className={classes.favoritesPopper}
        >
          <ClickAwayListener onClickAway={() => setFavoritesOpen(false)}>
            <Paper className={classes.favoritesPaper}>
              <Box
                className={classes.favoritesList}
                onMouseEnter={() => setFavoritesOpen(true)}
                onMouseLeave={() => setFavoritesOpen(false)}
              >
                {favorites.length > 0 && (
                  <ButtonBase className={classes.rotateAllButton} onClick={handleRotateAllClick}>
                    <AutorenewIcon className={classes.rotateIcon} />
                    <Typography>Rotate All</Typography>
                  </ButtonBase>
                )}
                {favorites.length === 0 ? (
                  <Typography className={classes.noFavorites}>No favorite podcasts selected</Typography>
                ) : (
                  favorites.map((favorite) => (
                    <ButtonBase
                      key={favorite.podcastId}
                      className={classes.favoriteItem}
                      onClick={() => handleFavoritePodcastClick(favorite)}
                      disableRipple={false}
                      TouchRippleProps={{
                        className: classes.rippleEffect,
                      }}
                    >
                      <Typography>{favorite.name}</Typography>
                    </ButtonBase>
                  ))
                )}
              </Box>
            </Paper>
          </ClickAwayListener>
        </Popper>
      </Box>
    </>
  );
};
