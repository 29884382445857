import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#1C1B1B',
    backgroundImage: (props) => `url("${props.bg}")`,
    width: '100%',
    minHeight: 'var(--browse-height, 100vh)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 150px 15px',
    marginTop: 20,
    [theme.breakpoints.down('md')]: {
      padding: '0 15px 15px',
    },
  },
  headerTop: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    flexWrap: 'wrap',
  },
  logoWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '1.5rem 0',
  },
  logo: {
    maxWidth: 350,
    height: 'auto',
  },
  divider: {
    width: '100%',
    height: 1,
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
    margin: '0.7rem 0',
  },
  showMoreButton: {
    color: '#fff !important',
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 800,
    marginTop: '1rem',
    backgroundImage: (props) => `url("${props.bgBtn}")`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    '&:hover': {
      backgroundColor: 'rgba(70, 192, 236, 0.04)',
    },
  },
  statsWrapper: {
    padding: '0 150px',
  },
  headerVisible: {},
  unsubscribeWrapper: {
    backgroundColor: '#1C1B1B',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    padding: '20px',
    backgroundImage: (props) => `url(${props.bg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    '& .MuiTypography-root': {
      color: 'white',
      textAlign: 'center',
      padding: '20px',
      borderRadius: '8px',
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
    },
    '& .MuiCircularProgress-root': {
      color: 'white',
    },
  },
}));
