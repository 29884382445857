export const countries = [
  'Australia',
  'Canada',
  'Denmark',
  'Finland',
  'France',
  'Germany',
  'Iceland',
  'India',
  'Ireland',
  'Malaysia',
  'New Zealand',
  'Norway',
  'South Africa',
  'Spain',
  'Sweden',
  'UAE',
  'United Kingdom',
  'United States',
];
