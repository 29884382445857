import { useState, useCallback, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Box, Typography, Autocomplete, TextField, CircularProgress } from '@mui/material';
import debounce from 'lodash/debounce';
import {
  setCurrentPodcast,
  fetchPodcasts,
  setSelectedGenre,
  resetPodcastsByGenre,
  setBrowseMode,
  setShowBrowseGrid,
  resetBrowseMode,
} from '../../redux/slices/podcastsSlice';
import { useStyles, StyledPopper } from './styles';
import { BrowseChartsFilters } from '../BrowsePodcasts/BrowsePodcastsFilters';
import { FavoritesList } from '../FavoritesList/FavoritesList';
import cx from 'classnames';
import { updatePodcastUrl } from '../../utils/updatePodcastUrl';

export const Search = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const {
    podcasts: { data, loading },
    currentPodcast,
    browseMode,
    demoModePodcasts,
  } = useSelector((state) => state.podcasts);
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    const podId = searchParams.get('podId');
    if (podId) {
      dispatch(fetchPodcasts(podId));
    }
  }, [dispatch, searchParams]);

  useEffect(() => {
    if (data.length === 1 && !demoModePodcasts.length) {
      setSearchValue(data[0].collectionName);
      updatePodcastUrl(data[0].collectionId);
      dispatch(setCurrentPodcast(data[0]));
    }
  }, [data, dispatch, demoModePodcasts]);

  useEffect(() => {
    if (currentPodcast) {
      setSearchValue(currentPodcast.collectionName);
    }
  }, [currentPodcast]);

  const debouncedFetch = useCallback(
    (value) => {
      if (value.trim()) {
        setIsSearching(true);
        dispatch(fetchPodcasts(value)).finally(() => setIsSearching(false));
      }
    },
    [dispatch]
  );

  const debouncedSearch = useMemo(() => debounce(debouncedFetch, 800), [debouncedFetch]);

  const handleInputChange = (event, value, reason) => {
    if (reason !== 'reset') {
      setSearchValue(value);
    }
    if (reason === 'input') {
      debouncedSearch(value);
    }
  };

  const hideBrowseMode = () => {
    dispatch(setShowBrowseGrid(false));
    dispatch(resetBrowseMode());
  };

  const handlePodcastSelect = (event, podcast) => {
    if (podcast) {
      hideBrowseMode();
      dispatch(setCurrentPodcast(podcast));
      updatePodcastUrl(podcast.collectionId);
    }
  };

  const handleClose = () => {
    if (!isSearching) {
      setOpen(false);
      dispatch(setSelectedGenre(null));
      dispatch(resetPodcastsByGenre());
    }
  };

  const sortedPodcasts = useMemo(() => {
    return [...data].sort((a, b) => {
      if (a.collectionName.toLowerCase() === searchValue.toLowerCase()) return -1;
      if (b.collectionName.toLowerCase() === searchValue.toLowerCase()) return 1;
      return 0;
    });
  }, [data, searchValue]);

  return (
    <Box className={classes.searchWrapper}>
      <Box className={classes.navigationWrapper}>
        <Box
          className={cx(classes.navLink, { [classes.activeNav]: !browseMode })}
          onClick={() => dispatch(setBrowseMode(false))}
        >
          <Typography variant="h7" component="h2" className={classes.searchTitle}>
            Find your podcast
          </Typography>
        </Box>
        <Box className={classes.divider}>|</Box>
        <Box
          className={cx(classes.navLink, { [classes.activeNav]: browseMode })}
          onClick={() => {
            dispatch(setBrowseMode(true));
            dispatch(setShowBrowseGrid(true));
          }}
        >
          <Typography variant="h7" component="h2" className={classes.searchTitle}>
            Browse charts
          </Typography>
        </Box>
        <FavoritesList />
      </Box>
      {browseMode ? (
        <BrowseChartsFilters />
      ) : (
        <Autocomplete
          slots={{
            popper: StyledPopper,
          }}
          className={classes.searchAutocomplete}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={handleClose}
          onChange={(event, newValue) => {
            handlePodcastSelect(event, newValue);
          }}
          inputValue={searchValue}
          onInputChange={(event, value, reason) => handleInputChange(event, value, reason)}
          filterOptions={(x) => x}
          getOptionLabel={(option) => option.collectionName || ''}
          options={sortedPodcasts}
          loading={loading}
          clearOnBlur={false}
          noOptionsText="No podcasts"
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Podcast name"
              slotProps={{
                input: {
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                },
              }}
            />
          )}
          renderOption={(props, option) => (
            <li {...props} key={option.collectionId} style={{ gap: '10px' }}>
              {option.artworkUrl30 && <img src={option.artworkUrl30} alt="" />}
              {option.collectionName}
            </li>
          )}
        />
      )}
    </Box>
  );
};
