import { useSelector } from 'react-redux';
import {
  Box,
  Typography,
  Tooltip,
  IconButton,
  Link,
  Popper,
  Paper,
  ClickAwayListener,
  Snackbar,
  TextField,
  InputAdornment,
} from '@mui/material';
import { useStyles } from './styles';
import { useState, useCallback } from 'react';
import ShareIcon from '@mui/icons-material/Share';
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import EmailIcon from '@mui/icons-material/Email';
import AppleIcon from '../../assets/images/apple-icon.png';
import SpotifyIcon from '../../assets/images/spotify-icon.png';

const CONST = {
  EMAIL: 'email',
  FACEBOOK: 'facebook',
  X: 'X',
};

export const PlatformsAndSocials = () => {
  const classes = useStyles();
  const { currentPodcast } = useSelector((state) => state.podcasts);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showCopyNotification, setShowCopyNotification] = useState(false);

  const handleMouseEnter = useCallback((event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setOpen(false);
  }, []);

  const handleCopyLink = useCallback(() => {
    navigator.clipboard.writeText(window.location.href);
    setShowCopyNotification(true);
    setOpen(false);
  }, []);

  const handleShare = useCallback(
    (platform) => {
      const url = window.location.href;
      const text = `Check out ${currentPodcast.collectionName} podcast!`;
      let shareUrl = '';

      switch (platform) {
        case CONST.FACEBOOK:
          shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
          break;
        case CONST.X:
          shareUrl = `https://x.com/intent/tweet?text=${encodeURIComponent(text)}&url=${encodeURIComponent(
            url
          )}`;
          break;
        case CONST.EMAIL:
          shareUrl = `mailto:?subject=${encodeURIComponent(text)}&body=${encodeURIComponent(url)}`;
          break;
        default:
          return;
      }

      window.open(shareUrl, '_blank', 'noopener,noreferrer');
      setOpen(false);
    },
    [currentPodcast.collectionName]
  );

  const tooltipProps = {
    enterDelay: 200,
    leaveDelay: 10,
    arrow: true,
    placement: 'top',
  };

  return (
    <>
      <Box className={classes.platformLinks}>
        <Tooltip title="Listen on Apple Podcasts" {...tooltipProps}>
          <IconButton
            component={Link}
            href={currentPodcast.collectionViewUrl}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.platformButton}
          >
            <img src={AppleIcon} alt="Apple" className={classes.platformIcon} />
          </IconButton>
        </Tooltip>
        {currentPodcast.spotifyUrl && (
          <Tooltip title="Listen on Spotify" {...tooltipProps}>
            <IconButton
              component={Link}
              href={currentPodcast.spotifyUrl}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.platformButton}
            >
              <img src={SpotifyIcon} alt="Spotify" className={classes.platformIcon} />
            </IconButton>
          </Tooltip>
        )}
        <Box onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} className={classes.shareWrapper}>
          <Tooltip title="Share" {...tooltipProps}>
            <IconButton className={classes.shareButton}>
              <ShareIcon />
            </IconButton>
          </Tooltip>
          <Popper open={open} anchorEl={anchorEl} placement="bottom-start" className={classes.sharePopper}>
            <ClickAwayListener onClickAway={() => setOpen(false)}>
              <Paper className={classes.sharePaper}>
                <Box
                  className={classes.shareList}
                  onMouseEnter={() => setOpen(true)}
                  onMouseLeave={() => setOpen(false)}
                >
                  <Box onClick={() => handleShare(CONST.FACEBOOK)} className={classes.shareItem}>
                    <Box className={`${classes.socialIcon} ${classes.facebookIcon}`}>
                      <FacebookIcon />
                    </Box>
                    <Typography>Facebook</Typography>
                  </Box>
                  <Box onClick={() => handleShare(CONST.X)} className={classes.shareItem}>
                    <Box className={`${classes.socialIcon} ${classes.xIcon}`}>
                      <XIcon />
                    </Box>
                    <Typography>X.com</Typography>
                  </Box>
                  <Box onClick={() => handleShare(CONST.EMAIL)} className={classes.shareItem}>
                    <Box className={`${classes.socialIcon} ${classes.emailIcon}`}>
                      <EmailIcon />
                    </Box>
                    <Typography>Email</Typography>
                  </Box>
                  <Box className={classes.shareItem}>
                    <TextField
                      value={window.location.href}
                      variant="outlined"
                      size="small"
                      className={classes.linkField}
                      onClick={handleCopyLink}
                      slotProps={{
                        input: {
                          endAdornment: (
                            <InputAdornment position="end">
                              <Box onClick={handleCopyLink} className={classes.copyButton}>
                                Copy
                              </Box>
                            </InputAdornment>
                          ),
                        },
                      }}
                    />
                  </Box>
                </Box>
              </Paper>
            </ClickAwayListener>
          </Popper>
        </Box>
      </Box>
      <Snackbar
        open={showCopyNotification}
        autoHideDuration={2000}
        onClose={() => setShowCopyNotification(false)}
        message="Link copied to clipboard"
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        ContentProps={{
          sx: {
            backgroundColor: '#f56100',
            fontWeight: 600,
            fontSize: 15,
          },
        }}
      />
    </>
  );
};
