import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useStyles } from './styles';
import { Box, Typography, CircularProgress, IconButton } from '@mui/material';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import bgBtn from '../../assets/images/btn-back.png';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import { setSelectedGenre, resetPodcastsByGenre } from '../../redux/slices/podcastsSlice';
import { PodcastCard } from '../PodcastCard/PodcastCard';

const NextArrow = (props) => {
  const { onClick, currentSlide, slideCount } = props;
  const isLastSlide = currentSlide === slideCount - 5;

  return (
    <div
      className={cx('slick-arrow', 'slick-next', {
        'slick-disabled': isLastSlide,
      })}
      onClick={onClick}
    >
      <ArrowForwardIosIcon />
    </div>
  );
};

const PrevArrow = (props) => {
  const { onClick, currentSlide } = props;

  return (
    <div
      className={cx('slick-arrow', 'slick-prev', {
        'slick-disabled': currentSlide === 0,
      })}
      onClick={onClick}
    >
      <ArrowBackIosNewIcon />
    </div>
  );
};

const getSliderSettings = (filteredPodcasts) => ({
  dots: false,
  infinite: false,
  speed: 1000,
  touchThreshold: 100,
  slidesToShow: Math.min(5, filteredPodcasts.length),
  slidesToScroll: Math.min(5, filteredPodcasts.length),
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  variableWidth: false,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: Math.min(4, filteredPodcasts.length),
        slidesToScroll: Math.min(4, filteredPodcasts.length),
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: Math.min(3, filteredPodcasts.length),
        slidesToScroll: Math.min(3, filteredPodcasts.length),
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
});

const PodcastsCarousel = () => {
  const { selectedGenre, podcastsByGenre, status, currentPodcast } = useSelector(
    (state) => state.podcasts
  );
  const [activeSlides, setActiveSlides] = useState([0]);
  const [isVisible, setIsVisible] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles({ bgBtn });
  const sliderRef = useRef(null);
  const loading = status === 'loading';

  useEffect(() => {
    setIsVisible(false);
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 100);
    return () => clearTimeout(timer);
  }, [selectedGenre]);

  const filteredPodcasts = useMemo(() => {
    return selectedGenre ? podcastsByGenre[selectedGenre] || [] : [];
  }, [podcastsByGenre, selectedGenre]);

  useEffect(() => {
    if (currentPodcast && filteredPodcasts.length > 0) {
      const currentPodcastIndex = filteredPodcasts.findIndex(
        (podcast) => podcast.name.toLowerCase() === currentPodcast.collectionName.toLowerCase()
      );

      const isMobile = window.matchMedia('(max-width: 600px)').matches;

      if (currentPodcastIndex !== -1 && sliderRef?.current) {
        let slideToGo;
        if (isMobile) {
          slideToGo = currentPodcastIndex;
        } else {
          const slidesToShow = window.innerWidth > 1200 ? 5 : window.innerWidth > 900 ? 4 : 3;
          const centerOffset = Math.floor(slidesToShow / 2);
          slideToGo = Math.max(0, currentPodcastIndex - centerOffset);
        }


        const sliderParentElement = sliderRef.current?.innerSlider?.list?.parentElement;

        if (sliderParentElement) {
          sliderParentElement.classList.add('auto-scrolling');

          setTimeout(() => {
            if (sliderRef?.current?.slickGoTo) {
              sliderRef.current.slickGoTo(slideToGo);

              setTimeout(() => {
                sliderParentElement.classList.remove('auto-scrolling');
              }, 2000);
            }
          }, 1000);
        }
      }
    }
  }, [currentPodcast, filteredPodcasts]);

  const settings = getSliderSettings(filteredPodcasts);

  if (filteredPodcasts.length === 0) {
    return (
      <Box className={classes.carouselContent}>
        {loading ? (
          <CircularProgress sx={{ margin: '20px auto' }} />
        ) : (
          <>
            <Box className={classes.closeButtonWrapper}>
              <IconButton
                className={classes.closeButton}
                onClick={() => {
                  dispatch(setSelectedGenre(null));
                  dispatch(resetPodcastsByGenre());
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Typography className={classes.noPodcastsText}>No podcasts found for {selectedGenre}</Typography>
          </>
        )}
      </Box>
    );
  }

  const handleClose = () => {
    dispatch(setSelectedGenre(null));
  };

  return (
    <Box 
      className={cx(classes.carouselContent, {
        visible: isVisible,
      })}
    >
      <Box className={classes.closeButtonWrapper}>
        <IconButton className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Slider
        beforeChange={(_, next) => setActiveSlides([...activeSlides, next])}
        afterChange={(currentSlide) => setActiveSlides([currentSlide])}
        ref={sliderRef}
        {...settings}
        className={classes.slider}
      >
        {filteredPodcasts.map((podcast, index) => (
          <PodcastCard
            classes={classes}
            key={index}
            podcast={podcast}
            hidden={activeSlides.includes(index) ? undefined : true}
          />
        ))}
      </Slider>
    </Box>
  );
};

export default PodcastsCarousel;
