import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  tooltip: {
    position: 'absolute',
    zIndex: 1000,
    pointerEvents: 'none',
    transform: 'translate(-50%, -120%)',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.9)',
    borderRadius: 8,
    padding: 5,
    gap: 5,
    backdropFilter: 'blur(8px)',
    border: '1px solid rgba(255, 255, 255, 0.1)',
    maxWidth: 300,
  },
  image: {
    width: 60,
    height: 60,
    borderRadius: 6,
    objectFit: 'cover',
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  rank: {
    color: '#f56100',
    fontSize: '1.3rem !important',
    fontWeight: '600 !important',
  },
  name: {
    color: 'white',
    fontSize: '0.9rem !important',
    fontWeight: '400 !important',
    display: '-webkit-box !important',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  historyRankUp: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 20,
    fontWeight: 600,
    color: '#9df500',
    '& svg': {
      fill: '#9df500',
    },
  },
  historyRankDown: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 20,
    fontWeight: 600,
    color: '#f50000',
  },
  historyRank: {
    boxSizing: 'border-box',
    color: 'white',
    borderRadius: '50%',
    backgroundColor: '#2f2f2f',
    width: 50,
    height: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transform: 'scale(0.8)',
  },
})); 