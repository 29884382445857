import React, { memo } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { ReactComponent as AppleIcon } from '../../assets/images/apple-icon.svg';
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';
import { useStyles } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  setSelectedGenre,
  resetPodcastsByGenre,
  loadPodcastCharts,
  setShowBrowseGrid,
  setBrowseMode,
  resetBrowseMode,
  setCurrentPodcast,
} from '../../redux/slices/podcastsSlice';
import { updatePodcastUrl } from '../../utils/updatePodcastUrl';
import cx from 'classnames';

export const PodcastCard = memo(({ podcast, hidden, classes: propClasses }) => {
  const baseClasses = useStyles();
  const classes = propClasses || baseClasses;
  const dispatch = useDispatch();
  const { showBrowseGrid } = useSelector((state) => state.podcasts);

  const calculateRankChange = (podcast) => {
    const historyRanks = podcast.historyRank;
    if (historyRanks.length < 2) return null;

    const lastRank = parseInt(historyRanks[historyRanks.length - 1].rank);
    const prevRank = parseInt(historyRanks[historyRanks.length - 2].rank);
    const difference = prevRank - lastRank;

    if (difference === 0 || historyRanks.length < 2) {
      return (
        <>
          <span>—</span>
        </>
      );
    }

    return difference > 0 ? (
      <span className={classes.historyRankUp}>
        <NorthIcon />
        {difference}
      </span>
    ) : (
      <span className={classes.historyRankDown}>
        <SouthIcon />
        {-difference}
      </span>
    );
  };

  const handleLoadCharts = (podcast) => {
    dispatch(setSelectedGenre(null));
    dispatch(resetPodcastsByGenre());
    dispatch(loadPodcastCharts(podcast)).then((response) => {
      updatePodcastUrl(podcast.podcastId);
      dispatch(setCurrentPodcast(response.payload));
    });

    dispatch(setShowBrowseGrid(false));
    dispatch(setBrowseMode(false));
    dispatch(resetBrowseMode());
  };

  return (
    <div
      className={cx(classes.podcastCard, {
        browseCard: showBrowseGrid,
      })}
      hidden={hidden}
    >
      <Box className={classes.podcastImageWrapper}>
        <img
          src={podcast.images[2].label}
          alt={podcast.name}
          className={cx(classes.podcastImage, {
            browseImage: showBrowseGrid,
          })}
          loading="lazy"
          decoding="async"
        />
        <Box className={classes.imageOverlay}>
          <Button className={classes.overlayButton} onClick={() => handleLoadCharts(podcast)}>
            Load These Charts
          </Button>
          <Button
            className={classes.overlayButton}
            href={`https://podcasts.apple.com/eg/podcast/id${podcast.podcastId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Open in Apple Podcasts
          </Button>
        </Box>
      </Box>
      <Typography className={classes.podcastTitle}>{podcast.name}</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Box className={classes.podcastIcon}>
          <AppleIcon />
        </Box>
        <Typography className={classes.podcastGenre}>{podcast.genre}</Typography>
      </Box>
      <Typography className={classes.podcastCountry}>{podcast.country}</Typography>
      <Box className={classes.rankContainer}>
        <Typography className={classes.podcastRank}>{podcast.rank}</Typography>
        <Typography className={classes.historyRank}>{calculateRankChange(podcast)}</Typography>
      </Box>
    </div>
  );
});
