const randomCountries = [
  'United States',
  'United Kingdom',
  'Canada',
  'France',
  'Spain',
  'Germany',
  'Denmark',
  'Finland',
  'Iceland',
  'Ireland',
  'South Africa',
  'Australia',
  'New Zealand',
  'Norway',
  'Sweden',
  'UAE',
  'India',
  'Malaysia',
];

export function getRandomCountry() {
  const randomIndex = Math.floor(Math.random() * randomCountries.length);
  return randomCountries[randomIndex];
}

export function getRandomPodcast(podcasts) {
  const randomIndex = Math.floor(Math.random() * podcasts.length);
  return podcasts[randomIndex].collectionName === 'Byte' ? podcasts[randomIndex + 1] : podcasts[randomIndex];
}
