import React, { useEffect, useState } from 'react'
import { useStyles } from './styles';
import bg from '../assets/images/bg.svg';
import bgBtn from '../assets/images/btn.png';
import { unsubscribe } from '../api/email';
import { useSearchParams } from 'react-router-dom';
import { Box, Typography, CircularProgress, Grid2 } from '@mui/material';

export const Unsubscribe = () => {
  const classes = useStyles({ bg, bgBtn });
  const [searchParams] = useSearchParams();
  const [status, setStatus] = useState('loading');
  const [error, setError] = useState(null);
  const [artworkUrl, setArtworkUrl] = useState(null);

  useEffect(() => {
    const notifyId = searchParams.get('notifyId');
    const podcastId = searchParams.get('podcastId');

    if (!notifyId) {
      setStatus('error');
      setError('Missing required parameters');
      return;
    }

    const handleUnsubscribe = async () => {
      try {
        const response = await unsubscribe({ notifyId, podcastId });
        if (response?.result?.artworkUrl600) {
          setArtworkUrl(response.result.artworkUrl600);
        }
        setStatus('success');
      } catch (error) {
        setStatus('error');
        setError(error.message || 'Failed to unsubscribe');
      }
    };

    handleUnsubscribe();
  }, [searchParams]);

  return (
    <Box className={classes.unsubscribeWrapper}>
      {status === 'loading' && (
        <CircularProgress />
      )}
      {status === 'success' && (
        <Grid2 container direction="column" alignItems="center">
          {artworkUrl && (
            <Box mb={2}>
              <img 
                src={artworkUrl} 
                alt="Podcast artwork" 
                style={{ width: 200, height: 200, borderRadius: 8 }}
              />
            </Box>
          )}
          <Box mb={2}>
            <Typography variant="h6">
              You have been successfully unsubscribed from podcast notifications.
            </Typography>
          </Box>
        </Grid2>
      )}
      {status === 'error' && (
        <Typography variant="h6" color="error">
          {error || 'An error occurred while unsubscribing.'}
        </Typography>
      )}
    </Box>
  )
}
